<h3>Bulk Transfers</h3>

<ng-container *ngIf="(bulkTransfers | async)?.length==0 else listBulkTransfers">
  <div class="alert alert-warning" role="alert">
    No bulk transfers found, please create one.
  </div>
</ng-container>

<ng-template #listBulkTransfers>
  <div>
    <table class="table">
      <thead class="thead-light">
      <tr>
        <th scope="col">BulkTransfer ID</th>
        <th scope="col">Total Transfers</th>
        <th scope="col">Total Transfers Not Processed</th>
        <th scope="col">Status</th>

      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of (bulkTransfers | async)">
        <td><a routerLink="/bulk-transfers/{{item.bulkTransferId}}">{{item.bulkTransferId}}</a></td>
        <td>{{item.individualTransfers.length}}</td>
        <td>{{item.transfersNotProcessedIds.length}}</td>
        <td>{{item.status}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</ng-template>