<h2 class="mb-4">Settlement Initiation Report</h2>

<div class="filterbox">
	<form
		[formGroup]="settlementIdForm"
		(ngSubmit)="searchReports()"
		class="d-flex align-items-end justify-content-between mx-3"
		style="gap: 30px"
	>
		<div class="form-group" style="width: 250px">
			<label for="settlementId">Settlement ID:</label>
			<input
				id="settlementId"
				formControlName="settlementId"
				class="form-control"
				required
				aria-required="true"
				placeholder="Enter settlement ID"
			/>
		</div>

		<div class="form-group">
			<button
				type="submit"
				class="form-control btn btn-primary d-flex align-items-center px-3">
				Run
			</button>
		</div>
	</form>
</div>

<div *ngIf="showResults">
	<div class="px-3 d-flex flex-column" style="margin-top: 30px; gap: 6px">
		<div class="d-flex justify-content-between">
			<h3 style="font-size: 22px; margin-bottom: 15px">
				Settlement Initiation Report
			</h3>

			<button
				class="btn btn-primary"
				(click)="downloadInitiationReport()"
			>
				Export
			</button>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">Settlement ID</p>
			<p>{{ settlementInfo?.settlementId || "-" }}</p>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">Settlement Created Date</p>
			<p>{{ (settlementInfo?.settlementCreatedDate | momentDate) || "-" }}</p>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">Time Zone OffSet</p>
			<p>{{ '' | timezoneOffset }}</p>
		</div>
	</div>

	<table
		class="table mb-0"
		style="display: block; overflow-x: auto; margin-top: 30px"
	>
		<thead class="thead-light">
			<tr>
				<th scope="col" style="min-width: 160px">Participant</th>
				<th scope="col" style="min-width: 400px">
					Participant (Bank Identifier)
				</th>
				<th scope="col" style="min-width: 160px">Balance</th>
				<th scope="col" style="min-width: 160px">
					Settlement Transfer
				</th>
				<th scope="col" style="min-width: 160px">Currency</th>
			</tr>
		</thead>

		<tbody>
			<tr *ngFor="let initiationReport of initiationReports | async">
				<td style="min-width: 160px">
					{{ initiationReport.participantId }}
				</td>
				<td style="min-width: 400px">
					{{ initiationReport.participantBankIdentifier || "-" }}
				</td>
				<td style="min-width: 160px">
					{{ initiationReport.balance || "-" }}
				</td>
				<td style="min-width: 160px; text-align: right;">
					{{ initiationReport.settlementTransfer }}
				</td>
				<td style="min-width: 160px">
					{{ initiationReport.currency }}
				</td>
			</tr>
		</tbody>
	</table>
</div>
