<h3>Settlement Transfers</h3>

<div>
	<table class="table">
		<thead class="thead-light">
		<tr>
			<th scope="col">Transfer Id</th>
			<th scope="col">Payer Id</th>
			<th scope="col">Payee Id</th>
			<th scope="col">Amount</th>
			<th scope="col">Timestamp</th>
			<th scope="col">Batch Id</th>

		</tr>
		</thead>
		<tbody>
		<ng-container *ngIf="(transfers | async)?.length==0">
			<tr class="">
				<td colspan="6">No transfers found</td>
			</tr>
		</ng-container>
		<tr *ngFor="let item of (transfers | async)">
			<td><a routerLink="/transfers/{{item.transferId}}">{{item.transferId}}</a></td>
			<td><a routerLink="/participants/{{item.payerFspId}}">{{item.payerFspId}}</a></td>
			<td><a routerLink="/participants/{{item.payeeFspId}}">{{item.payeeFspId}}</a></td>
			<td style="text-align: right;">{{ formatCommaSeparator(item.amount) }} ({{item.currencyCode}})</td>
			<td>{{item.transferTimestamp | momentDate }}</td>
			<td><a routerLink="/settlements/batches" [queryParams]="{batchId: item.batchId, currencyCode: item.currencyCode}">{{item.batchId}}</a></td>

			<td *ngIf="item.matrixId"><a routerLink="/settlements/matrix/{{item.matrixId}}">Matrix</a></td>
		</tr>
		</tbody>
	</table>
</div>

<div class="list-page-navigation">
    <nav aria-label="Page navigation" *ngIf="paginateResult | async; let paginateObj">
      <ul class="pagination justify-content-end">
        <li class="pagesize d-flex align-items-center">
          <span>Items per page:</span>
          <select (change)="_fetchTransfers(0)" class="rows-selector form-control ml-2"
              id="pageSize">
            <option value="5" [selected]="paginateObj.pageSize == 5">5</option>
            <option value="10" [selected]="paginateObj.pageSize == 10">10</option>
            <option value="25" [selected]="paginateObj.pageSize == 25">25</option>
            <option value="50" [selected]="paginateObj.pageSize == 50">50</option>
            <option value="100" [selected]="paginateObj.pageSize == 100">100</option>
          </select>
        </li>

        <li class="gotopage d-flex align-items-center">
          <span >Go to page</span>
          <select (change)="_fetchTransfers()" class="rows-selector form-control ml-2" id="pageIndex">
            <option *ngFor="let item of [].constructor(paginateObj.totalPages); let i = index" [value]="i"
                [selected]="paginateObj.current == i+1">{{i+1}}</option>
          </select>

        </li>

        <li class="d-flex align-items-center">
          <span>Page {{paginateObj.current}} of {{paginateObj.totalPages}}</span>
        </li>

        <!-- first-->
        <li [ngClass]="{'disabled' : paginateObj.prev == null}">
          <a class="page-link" aria-label="Previous" (click)="_fetchTransfers(0)">
            <span aria-hidden="true">&lt;&lt;</span>
            <span class="sr-only">First</span>
          </a>
        </li>

        <!-- previous-->
        <li [ngClass]="{'disabled' : paginateObj.prev == null}">
          <a class="page-link" aria-label="Previous" (click)="_fetchTransfers(paginateObj.prev ? paginateObj.prev-1 : 0)">
            <span aria-hidden="true">&lt;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>

        <!-- next-->
        <li [ngClass]="{'disabled' : paginateObj.next == null}">
          <a class="page-link" aria-label="Next" (click)="_fetchTransfers(paginateObj.next ? paginateObj.next-1 : 0)">
            <span aria-hidden="true">&gt;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>

        <!-- last-->
        <li [ngClass]="{'disabled' : paginateObj.next == null}">
          <a class="page-link" aria-label="Next" (click)="_fetchTransfers(paginateObj.totalPages-1)">
            <span aria-hidden="true">&gt;&gt;</span>
            <span class="sr-only">Last</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
