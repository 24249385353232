<h2>Test page <span class="text-secondary">(dev mode enable)</span></h2>
<hr>


<div *ngIf="(isLoggedIn | async)">


	<h2>Quotes</h2>
	<a href="/quotes/new" class="btn btn-primary btn-block col-md-3" >
		Create Test Quote
	</a>

	<hr>

	<h2>Bulk Quotes</h2>
	<a href="/bulk-quotes/new" class="btn btn-primary btn-block col-md-3" >
		Create Test Bulk Quote
	</a>

	<hr>

	<h2>Transfers</h2>
	<a href="/transfers/new" class="btn btn-primary btn-block col-md-3" >
		Create Test Transfer
	</a>

	<hr>

	<h2>Bulk Transfers</h2>
	<a href="/bulk-transfers/new" class="btn btn-primary btn-block col-md-3" >
		Create Test Bulk Transfer
	</a>

	<hr>

	<h2>Account Lookup Tests</h2>

	<a href="/account-lookup" class="btn btn-primary btn-block col-md-3" >
		Account Lookup Dedicated Test Page
	</a>

	<hr>


</div>
