<div class="row mb-2">
  <div class="col">
    <h3>Quotes</h3>
  </div>
  <div class="col text-right">
<!--    <button class="btn btn-warning" routerLink="/quotes/new">-->
<!--      Create Test Quote-->
<!--    </button>-->
  </div>
</div>

<!--filter-->
<div class="list-page-filterbox">
  <form>
    <table class="table">
      <tr class="form-row justify-content-start">
        <div class="form-group col-md-2">
          <label for="filterQuoteAmountType">Amount Type</label>
          <select class="form-control" id="filterQuoteAmountType">
            <option value="{{ALL_STR_ID}}">(All)</option>
            <option value="{{item}}" *ngFor="let item of (keywordQuoteAmountType | async)">{{item}}</option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <label for="filterQuoteTransactionType">Transaction Type</label>
          <select class="form-control" id="filterQuoteTransactionType">
            <option value="{{ALL_STR_ID}}">(All)</option>
            <option value="{{item}}" *ngFor="let item of (keywordQuoteTransactionType | async)">{{item}}</option>
          </select>
        </div>

        <div class="form-group col-md-2">
          <label for="filterQuoteId">Quote ID</label>
          <input class="form-control" type="text" id="filterQuoteId" />
        </div>
        <div class="form-group col-md-2">
          <label for="filterTransactionId">Transaction ID</label>
          <input class="form-control" type="text" id="filterTransactionId" />
        </div>
        <div class="form-group col-md-2">
          <label for="filterBulkQuoteId">Bulk Quote ID</label>
          <input class="form-control" type="text" id="filterBulkQuoteId" />
        </div>

        <div class="form-group col-md-2">
					<label for="filterPayerId">Payer DFSP Name:</label>
					<select id="filterPayerId" class="form-control">
						<option value={{ALL_STR_ID}}>(All)</option>
						<option *ngFor="let item of participants | async" value="{{item.id}}">
							{{item.name}}
						</option>
					</select>
				</div>

				<div class="form-group col-md-2">
					<label for="filterPayeeId">Payee DFSP Name:</label>
					<select id="filterPayeeId" class="form-control">
						<option value={{ALL_STR_ID}}>(All)</option>
						<option *ngFor="let item of participants | async" value="{{item.id}}">
							{{item.name}}
						</option>
					</select>
				</div>

        <div class="form-group col-md-2">
          <label for="filterStatus">Status</label>
          <select class="form-control" id="filterStatus">
            <option value="{{ALL_STR_ID}}">(All)</option>
            <option value="{{item}}" *ngFor="let item of (keywordStatus | async)">{{item}}</option>
          </select>
        </div>
      </tr>

      <tr class="form-row">
        <div class="col-md-10">&nbsp;</div>
        
        <div class="form-group col-md-2">
          <label for="filterBtn">&nbsp;</label>

          <button
            type="submit"
            class="form-control btn btn-primary"
            id="filterBtn"
            (click)="search(0)">
            Search&nbsp;&nbsp;<i class="bi bi-search"></i>
          </button>
        </div>
      </tr>
    </table>
  </form>
</div>

<ng-container *ngIf="(quotes | async)?.length==0 else listQuotes">
  <div class="alert alert-warning" role="alert">
    No quotes found.
  </div>
</ng-container>


<ng-template #listQuotes>
  <div>
    <table class="table">
      <thead class="thead-light">
        <tr>
          <th scope="col">Quote ID</th>
          <th scope="col">BulkQuote ID</th>
          <th scope="col">Transaction ID</th>
          <th scope="col">Amount Type</th>
          <th scope="col">Transaction Type</th>
          <th scope="col">Payer DFSP Name</th>
          <th scope="col">Payee DFSP Name</th>
          <th scope="col">Status</th>
          <th scope="col">Error Reason</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of (quotes | async)">
          <td>
            <a routerLink="/quotes/{{ item.quoteId }}">{{ item.quoteId }}</a>
          </td>
          <td>{{ item.bulkQuoteId ? item.bulkQuoteId : "None" }}</td>
          <td>{{ item.transactionId }}</td>
          <td>{{ item.amountType }}</td>
          <td>{{ item.transactionType?.scenario }}</td>
          <td><a routerLink="/participants/{{item.payer?.partyIdInfo?.fspId}}">{{ item.payer?.partyIdInfo?.fspId }}</a></td>
          <td><a routerLink="/participants/{{item.payee?.partyIdInfo?.fspId}}">{{ item.payee?.partyIdInfo?.fspId }}</a></td>
          <td>{{ item.status }}</td>
          <td>{{ item.errorInformation ?? "-" }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <!--pagination
  TODO: put in component
  -->
  <hr>
  <div class="list-page-navigation">
    <nav aria-label="Page navigation" *ngIf="paginateResult | async; let paginateObj">
      <ul class="pagination justify-content-end">
        <li class="pagesize d-flex align-items-center">
          <span>Items per page:</span>
          <select (change)="search(0)" class="rows-selector form-control ml-2"
              id="pageSize">
            <option value="5" [selected]="paginateObj.pageSize == 5">5</option>
            <option value="10" [selected]="paginateObj.pageSize == 10">10</option>
            <option value="25" [selected]="paginateObj.pageSize == 25">25</option>
            <option value="50" [selected]="paginateObj.pageSize == 50">50</option>
            <option value="100" [selected]="paginateObj.pageSize == 100">100</option>
          </select>
        </li>
    
        <li class="gotopage d-flex align-items-center">
          <span >Go to page</span>
          <select (change)="search()" class="rows-selector form-control ml-2" id="pageIndex">
            <option *ngFor="let item of [].constructor(paginateObj.totalPages); let i = index" [value]="i"
                [selected]="paginateObj.current == i+1">{{i+1}}</option>
          </select>
    
        </li>
    
        <li class="d-flex align-items-center">
          <span>Page {{paginateObj.current}} of {{paginateObj.totalPages}}</span>
        </li>
    
        <!-- first-->
        <li [ngClass]="{'disabled' : paginateObj.prev == null}">
          <a class="page-link" aria-label="Previous" (click)="search(0)">
            <span aria-hidden="true">&lt;&lt;</span>
            <span class="sr-only">First</span>
          </a>
        </li>
    
        <!-- previous-->
        <li [ngClass]="{'disabled' : paginateObj.prev == null}">
          <a class="page-link" aria-label="Previous" (click)="search(paginateObj.prev ? paginateObj.prev-1 : 0)">
            <span aria-hidden="true">&lt;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
    
        <!-- next-->
        <li [ngClass]="{'disabled' : paginateObj.next == null}">
          <a class="page-link" aria-label="Next" (click)="search(paginateObj.next ? paginateObj.next-1 : 0)">
            <span aria-hidden="true">&gt;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
    
        <!-- last-->
        <li [ngClass]="{'disabled' : paginateObj.next == null}">
          <a class="page-link" aria-label="Next" (click)="search(paginateObj.totalPages-1)">
            <span aria-hidden="true">&gt;&gt;</span>
            <span class="sr-only">Last</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</ng-template>
