<div class="row mb-2">
	<div class="col">
		<h3>Role Management</h3>
	</div>
	<div class="col text-right">
		<button class="btn btn-success btn-labeled" routerLink="/security/roles/new">
			<span class="btn-label"><i class="bi bi-plus-lg"></i></span>Create Role
		</button>
	</div>
</div>

<table class="table table-striped">
	<thead class="thead-light">
	<tr>
		<th scope="col">Id</th>
		<th scope="col">Label Name</th>
		<th scope="col">Applications/Users role?</th>

	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let item of (roles | async)">
		<td> <a routerLink="/security/roles/{{item.id}}">{{item.id}}</a> </td>
		<td> {{item.labelName}}</td>
		<td>
			<span *ngIf="item.isApplicationRole" class="badge badge-secondary">Applications</span>
			<span *ngIf="!item.isApplicationRole" class="badge badge-primary">Users</span>
		</td>
	</tr>
	</tbody>
</table>
