import {Component, OnInit} from "@angular/core";
import {MessageService} from 'src/app/_services_and_types/message.service';
import {Association, Oracle} from 'src/app/_services_and_types/account-lookup_types';
import {AccountLookupService} from 'src/app/_services_and_types/account-lookup.service';
import {
	AbstractControl,
	FormControl,
	FormGroup,
	ValidationErrors,
	Validators,
} from '@angular/forms';
import { BehaviorSubject, Subscription } from "rxjs";
import { IParticipant } from "@pch-private/participant-bc-public-types-lib";
import { ParticipantsService } from "../_services_and_types/participants.service";
import { UnauthorizedError } from "../_services_and_types/errors";
import { Router } from "@angular/router";

@Component({
	selector: 'app-account-lookup-association-edit',
	templateUrl: './oracle-association-create.component.html',
})
export class AccountLookupAssociationCreationComponent implements OnInit {
	public form!: FormGroup;
	public association: Association = this._accountLookupService.createEmptyAssociation();
	public registerError: string = '';
	public isBuiltinSelected: boolean = true;
	public submitted: boolean = false;

	participants: BehaviorSubject<IParticipant[]> = new BehaviorSubject<IParticipant[]>([]);
	participantsSubs?: Subscription;

	partyTypeList = ["MSISDN", "PERSONAL_ID", "BUSINESS", "DEVICE", "ACCOUNT_ID", "IBAN", "ALIAS"];
	currencyList: string[] = [];
	
	constructor(
		private _router: Router,
		private _accountLookupService: AccountLookupService,
		private _participantsSvc: ParticipantsService,
		private _messageService: MessageService,
	) {

	}

	ngOnInit(): void {		
		console.log("AccountLookupComponent Participant Association ngOnInit");
    
		this.form = new FormGroup({
			"partyId": new FormControl("", [
				Validators.required,
				Validators.minLength(1)
			]),
			"partyType": new FormControl("", [
				Validators.required,
				Validators.minLength(1)
			]),
			"currency": new FormControl("", [
				Validators.required,
				Validators.minLength(1)
			]),
			"participantId": new FormControl("", [
				Validators.required,
				Validators.minLength(1)
			]),
			"subType": new FormControl("", [
				Validators.minLength(1)
			])
		});
	
		this.participantsSubs = this._participantsSvc.getAllParticipants().subscribe(
			(list) => {
				console.log("ParticipantsComponent ngOnInit - got getAllParticipants");
	
				for (let i = 0; i < list.items.length; i += 1) {
					for (let j = 0; j < list.items[i].participantAccounts.length; j += 1) {
						this.currencyList.push(list.items[i].participantAccounts[j].currencyCode);
					}
				}
	
				// Remove currency duplicates
				this.currencyList = this.currencyList.filter((item, index) => this.currencyList.indexOf(item) === index);
	
				this.participants.next(list.items);
			},
			error => {
				if (error && error instanceof UnauthorizedError) {
					this._messageService.addError(error.message);
				}
			}
		);

	}

	selectCurrency($event: Event) {
		const selectedCurrency = ($event.target as HTMLSelectElement).value;
	
		if (!selectedCurrency) {
			this._messageService.addError("Please select a valid currency.");
			return;
		}
	
		if (this.currencyList.includes(selectedCurrency)) {
			this.form.controls.currencyCode.setValue(selectedCurrency);
		} else {
			this._messageService.addError("Invalid currency selection.");
		}
	}

	register() {
		history.back();

		this.submitted = true;
	
		const requiredFields = [
			{ key: 'partyId', name: 'Party ID' },
			{ key: 'partyType', name: 'Party Type' },
			{ key: 'currency', name: 'Currency' },
			{ key: 'participantId', name: 'Participant ID' }
		];
	
		const invalidFields: string[] = [];
	
		for (const field of requiredFields) {
			const control = this.form.get(field.key);
			if (!control || control.invalid || !control.value) {
				control?.setErrors({ required: true });
				invalidFields.push(field.name); // Collect invalid field names
			}
		}
	
		if (invalidFields.length > 0) {
			const errorMessage = `The following fields are required: ${invalidFields.join(', ')}`;
			this._messageService.addError(errorMessage);
			return;
		}
	
		this._accountLookupService.registerOracleParticipantAssociation(this.form.value).subscribe(
			(oracleParticipantAssociation) => {
				if (oracleParticipantAssociation) {
					this._messageService.addSuccess("Oracle participant association created successfully!");
					this._router.navigate(["/account-lookup-associations"]);
					return;
				}
			},
			(error: Error) => {
				this._messageService.addError(error.message);
			}
		);
	}
	
	cancel() {
		this.association = this._accountLookupService.createEmptyAssociation();
		history.back();
	}
}
