<h2 class="mb-4">DFSP Settlement Statement Report</h2>

<div class="filterbox">
	<form
		[formGroup]="dfspFilterForm"
		(ngSubmit)="searchReports()"
		class="d-flex justify-content-between align-items-end mx-3"
	>

	<div class="flex-column">
		<div class="d-flex" style="gap: 30px">
			<div class="form-group" style="width: 250px">
				<label for="dfspName">DFSP Name:</label>
				<select
					id="dfspName"
					formControlName="dfspName"
					class="form-control"
					required
					aria-required="true"
				>
					<option value="">Choose DFSP</option>
					<option
						*ngFor="let participant of participants | async"
						value="{{ participant.id }}"
					>
						{{ participant.name }}
					</option>
				</select>
			</div>

			<div class="form-group" style="width: 250px">
				<label for="startDate">Start Date:</label>
				<input
					id="startDate"
					type="datetime-local"
					formControlName="startDate"
					class="form-control"
					required
					aria-required="true"
				/>
			</div>

			<div class="form-group" style="width: 250px">
				<label for="endDate">End Date:</label>
				<input
					id="endDate"
					type="datetime-local"
					formControlName="endDate"
					class="form-control"
					required
					aria-required="true"
				/>
			</div>

		</div>

		<div class="d-flex" style="gap: 30px">
			<div class="form-group" style="width: 250px">
				<label for="currency">Currency:</label>
				<select id="currency" class="form-control" formControlName="currencyCode">
				  <option value="" selected disabled>Choose Currency</option>
				  <option value="ALL">All</option>
				  <option *ngFor="let currency of (currencyCodeList | async)"  value="{{currency.code}}">{{currency.code}}</option>
				</select>
			</div>

			<div class="form-group" style="margin-top: 32px;">
				<button
					type="submit"
					class="form-control btn btn-primary d-flex align-items-center px-3">
					Run
				</button>
			</div>

		</div>
	</div>
	</form>

</div>

<div *ngIf="showResults">
	<div class="px-3 d-flex flex-column" style="margin-top: 30px; gap: 6px">
		<div class="d-flex justify-content-between">
			<h3 style="font-size: 22px; margin-bottom: 15px">
				{{ settlementStatementInfo?.dfspName || "-" }}
			</h3>

			<button class="btn btn-primary" *ngIf="(statementReports | async)?.length !== 0" (click)="downloadDFSPSettlementStatementReport()">
				Export
			</button>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">DFSP ID</p>
			<p>{{ settlementStatementInfo?.dfspId || "-" }}</p>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">DFSP Name</p>
			<p>{{ settlementStatementInfo?.dfspName || "-" }}</p>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">From Date</p>
			<p>{{ (settlementStatementInfo?.startDate | momentDate) || "-" }}</p>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">To Date</p>
			<p>{{ (settlementStatementInfo?.endDate | momentDate) || "-" }}</p>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">Currency</p>
			<p>{{ settlementStatementInfo?.currencyCode || "-" }}</p>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">TimeZoneOffSet</p>
			<p>{{ '' | timezoneOffset }}</p>
		</div>
	</div>

	<table
		class="table mb-5"
		style="display: block; overflow-x: auto; margin-top: 30px"
	>
		<thead class="thead-light">
			<tr>
				<th scope="col" style="min-width: 180px">Journal Entry Id</th>
				<th scope="col" style="min-width: 180px">Date Time</th>
				<th scope="col" style="min-width: 450px;">Process Description</th>
				<th scope="col" style="min-width: 160px">Funds In</th>
				<th scope="col" style="min-width: 160px">Funds Out</th>
				<th scope="col" style="min-width: 160px">Balance</th>
				<th scope="col" style="min-width: 120px">Currency</th>
				<th scope="col" style="min-width: 180px">Account Number</th>
			</tr>
		</thead>

		<div *ngIf="(statementReports | async)?.length === 0">
			<p class="text-center my-5">There is no available data in the selected date range and filter criteria. Please adjust your filters or select a different date range.</p>
		</div>

		<tbody>
			<tr *ngFor="let statementReport of statementReports | async; let i = index">

				<td style="min-width: 180px">{{ statementReport.journalEntryId }}</td>
				<td style="min-width: 180px">
					{{ statementReport.transactionDate | momentDate }}
				</td>
				<td style="min-width: 450px">{{ statementReport.processDescription }}</td>
				<td style="min-width: 160px; text-align: right;">{{ statementReport.fundsInAmount }}</td>
				<td style="min-width: 160px; text-align: right;"> {{ statementReport.fundsOutAmount }}</td>
				<td style="min-width: 160px; text-align: right;">{{ statementReport.balance }}</td>
				<td style="min-width: 120px">
					{{ statementReport.statementCurrencyCode }}
				</td>
				<td style="min-width: 180px">
					{{ statementReport.accountNumber }}
				</td>
			</tr>
		</tbody>
	</table>
</div>
