<h3>Application Privileges</h3>
<table class="table">
	<thead class="thead-light">
	<tr>
		<th scope="col">Label</th>
		<th scope="col">BC</th>
		<th scope="col">Privilege Set Version</th>
		<th scope="col">Description</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let item of (privileges | async)">
		<th scope="row">{{item.labelName}}</th>
		<td> {{item.boundedContextName}}</td>
		<td> {{item.boundedContextName}} <span class="badge badge-secondary">{{item.privilegeSetVersion}}</span></td>
    	<td> {{item.description}}</td>
	</tr>
	</tbody>
</table>
