import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, of } from "rxjs";
import { AuthenticationService } from "src/app/_services_and_types/authentication.service";
import { EventData } from "../_services_and_types/eventbus_types";
import { EventBusService } from "../_services_and_types/eventbus.service";
import { UserType } from "@pch-private/security-bc-public-types-lib";

@Injectable()
export class CanLoadWithUserType implements CanActivate {
	constructor(
		private _auth: AuthenticationService,
		private _router: Router,
		private _eventBusService: EventBusService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const url: string = state.url;

		// We hardcode it for now since we only have two types, HUB of DSFP (it's either allow it or not, adapt later if we add more user types)
		const requiredUserType: UserType = "HUB";

		return this.checkUserType(requiredUserType, url);
	}

	private checkUserType(requiredUserType: any, url: string): boolean {
		const currentUserType = this._auth.userType;

		if (currentUserType === requiredUserType) {
			return true;
		}

		this._auth.redirectUrl = url;
		this._eventBusService.emit(new EventData("UnauthorizedAccess", { attemptedUrl: url, requiredUserType }));
		this._router.navigate(["/"]);

		return false;
	}
}
