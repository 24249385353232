<h2>Create Test Quote <span class="text-secondary">(dev mode enable)</span></h2>
<hr>

<div class="mb-4">
  <button class="btn btn-primary" (click)="applyQuoteExample()">Apply Example Quote Values</button>
</div>


<h3>Request a new Quote</h3>
<form [formGroup]="form" (ngSubmit)="saveQuote()">

  <div class="form-row"> <!--form-row-->

    <!--quoteId-->
    <div class="form-group col-md-6">
      <label for="quoteId">Quote Id:</label>
      <input id="quoteId" formControlName="quoteId" type="text" class="form-control"
             [ngClass]="{'is-valid': form.controls['quoteId'].touched && form.controls['quoteId'].valid,
              'is-invalid': (submitted || form.controls['quoteId'].touched) && form.controls['quoteId'].invalid}">
    </div>

    <!--bulkQuoteId-->
    <!-- <div class="form-group col-md-12">
      <label for="bulkQuoteId">Bulk Quote Id:</label>
      <input id="bulkQuoteId" class="form-control" formControlName="bulkQuoteId" type="text">
    </div> -->

    <!--transactionId-->
    <div class="form-group col-md-6">
      <label for="transactionId">Transaction Id:</label>
      <input id="transactionId" class="form-control" formControlName="transactionId" type="text">
    </div>

	  <!--payer-->
	  <div class="form-group col-md-12 border-bottom">
		  <h4>Payer Information</h4>
	  </div>
	  <div class="form-group col-md-3">
		  <label for="payerPartyIdType">Party Type:</label>
		  <select id="payerPartyIdType" class="form-control" formControlName="payerPartyIdType">
			  <option *ngFor="let payerPartyIdType of partyIdTypeList"  value="{{payerPartyIdType}}">{{payerPartyIdType}}</option>
		  </select>
	  </div>
	  <div class="form-group col-md-3">
		  <label for="payerPartyIdentifier">Party Identifier:</label>
		  <input class="form-control" id="payerPartyIdentifier" formControlName="payerPartyIdentifier" type="text"
				 [ngClass]="{'is-valid': form.controls['payerPartyIdentifier'].touched && form.controls['payerPartyIdentifier'].valid,
             'is-invalid': (submitted || form.controls['payerPartyIdentifier'].touched) && form.controls['payerPartyIdentifier'].invalid}">
	  </div>
	  <div class="form-group col-md-3">
		  <label for="payerPartySubIdOrType">Party SubIdentifier:</label>
		  <input class="form-control" id="payerPartySubIdOrType" formControlName="payerPartySubIdOrType" type="text"
				 [ngClass]="{'is-valid': form.controls['payerPartySubIdOrType'].touched && form.controls['payerPartySubIdOrType'].valid,
             'is-invalid': (submitted || form.controls['payerPartySubIdOrType'].touched) && form.controls['payerPartySubIdOrType'].invalid}">
	  </div>
	  <div class="form-group col-md-3">
		  <label for="payerFspId">FSP Identifier:</label>
		  <select id="payerFspId" class="form-control" formControlName="payerFspId">
			  <option *ngFor="let participant of (participants | async)"  value="{{participant.id}}">{{participant.name}}</option>
		  </select>
	  </div>

    <!--payee-->
    <div class="form-group col-md-12 border-bottom">
      <h4>Payee Information</h4>
    </div>
    <div class="form-group col-md-3">
      <label for="payeePartyIdType">Party Type:</label>
      <select id="payeePartyIdType" class="form-control" formControlName="payeePartyIdType">
        <option *ngFor="let payerPartyIdType of partyIdTypeList"  value="{{payerPartyIdType}}">{{payerPartyIdType}}</option>
      </select>
    </div>
    <div class="form-group col-md-3">
      <label for="payeePartyIdentifier">Party Identifier:</label>
      <input class="form-control" id="payeePartyIdentifier" formControlName="payeePartyIdentifier" type="text"
             [ngClass]="{'is-valid': form.controls['payeePartyIdentifier'].touched && form.controls['payeePartyIdentifier'].valid,
             'is-invalid': (submitted || form.controls['payeePartyIdentifier'].touched) && form.controls['payeePartyIdentifier'].invalid}">
    </div>
    <div class="form-group col-md-3">
      <label for="payeePartySubIdOrType">Party SubIdentifier:</label>
      <input class="form-control" id="payeePartySubIdOrType" formControlName="payeePartySubIdOrType" type="text"
             [ngClass]="{'is-valid': form.controls['payeePartySubIdOrType'].touched && form.controls['payeePartySubIdOrType'].valid,
             'is-invalid': (submitted || form.controls['payeePartySubIdOrType'].touched) && form.controls['payeePartySubIdOrType'].invalid}">
    </div>
    <div class="form-group col-md-3">
      <label for="payeeFspId">FSP Identifier:</label>
      <select id="payeeFspId" class="form-control" formControlName="payeeFspId">
        <option *ngFor="let participant of (participants | async)"  value="{{participant.id}}">{{participant.name}}</option>
      </select>
    </div>



    <!--amount-->
    <div class="form-group col-md-12 border-bottom">
      <h4>Amount Information:</h4>
    </div>
    <div class="form-group col-md-4">
      <label for="amountType">Amount Type:</label>
      <select id="amountType" class="form-control" formControlName="amountType">
        <option *ngFor="let amountType of amountTypeList"  value="{{amountType}}">{{amountType}}</option>
      </select>
    </div>
    <div class="form-group col-md-4">
      <label for="currency">Currency:</label>
      <select id="currency" class="form-control" formControlName="currency">
        <option *ngFor="let currency of (currencyCodeList | async)"  value="{{currency.code}}">{{currency.code}}</option>
      </select>
    </div>
    <div class="form-group col-md-4">
      <label for="amount">Amount:</label>
      <input class="form-control" id="amount" formControlName="amount" type="text"
              [ngClass]="{'is-valid': form.controls['amount'].touched && form.controls['amount'].valid,
              'is-invalid': (submitted || form.controls['amount'].touched) && form.controls['amount'].invalid}">
    </div>

    <!--transaction-->
    <div class="form-group col-md-12 border-bottom">
      <h4>Transaction Information:</h4>
    </div>
    <div class="form-group col-md-4">
      <label for="scenario">Scenario:</label>
      <select id="scenario" class="form-control" formControlName="scenario">
        <option *ngFor="let scenario of scenarioList"  value="{{scenario}}">{{scenario}}</option>
      </select>
    </div>
    <div class="form-group col-md-4">
      <label for="initiator">Initiator:</label>
      <select id="initiator" class="form-control" formControlName="initiator">
        <option *ngFor="let initiator of initiatorList"  value="{{initiator}}">{{initiator}}</option>
      </select>
    </div>
    <div class="form-group col-md-4">
      <label for="initiatorType">Initiator Type:</label>
      <select id="initiatorType" class="form-control" formControlName="initiatorType">
        <option *ngFor="let initiatorType of initiatorTypeList"  value="{{initiatorType}}">{{initiatorType}}</option>
      </select>
    </div>
  </div> <!--form-row-->

  <div class="form-row d-flex mb-3">
    <div class="p-2">
      <button type="submit" class="btn btn-primary" [disabled]="!form.valid">Create Quote</button>
    </div>
    <div class="ml-auto p-2">
      <button class="btn btn-danger" (click)="cancel()">Cancel</button>
    </div>
  </div>
</form>
