<h2 class="mb-4">DFSP Settlement Detail Report</h2>

<div class="filterbox">
	<form
		[formGroup]="dfspFilterForm"
		(ngSubmit)="searchSettlementIds()"
		class="d-flex justify-content-between align-items-end mx-3"
	>
		<div class="d-flex" style="gap: 30px">
			<div class="form-group" style="width: 250px">
				<label for="dfspName">DFSP Name:</label>
				<select
					id="dfspName"
					formControlName="dfspName"
					class="form-control"
					required
					aria-required="true"
				>
					<option value="">Choose DFSP</option>
					<option
						*ngFor="let participant of participants | async"
						value="{{ participant.id }}"
					>
						{{ participant.name }}
					</option>
				</select>
			</div>

			<div class="form-group" style="width: 250px">
				<label for="startDate">Start Date:</label>
				<input
					id="startDate"
					type="datetime-local"
					formControlName="startDate"
					class="form-control"
					required
					aria-required="true"
				/>
			</div>

			<div class="form-group" style="width: 250px">
				<label for="endDate">End Date:</label>
				<input
					id="endDate"
					type="datetime-local"
					formControlName="endDate"
					class="form-control"
					required
					aria-required="true"
				/>
			</div>
		</div>

		<div class="form-group">
			<button
				type="submit"
				class="form-control btn btn-primary d-flex align-items-center px-3"
			>
				Search<i class="bi bi-search ml-2" style="font-size: 14px"></i>
			</button>
		</div>
	</form>

	<form
		*ngIf="showSettlementIdForm"
		[formGroup]="settlementIdForm"
		(ngSubmit)="searchReports()"
		class="d-flex align-items-end mx-3"
		style="gap: 30px"
	>
		<div class="form-group" style="width: 250px">
			<label for="settlementId">Settlement ID:</label>
			<select
				id="settlementId"
				formControlName="settlementId"
				class="form-control"
				required
				aria-required="true"
				(change)="onSettlementIdChange()"
			>
				<option value="">Choose Settlement ID</option>
				<option
					*ngFor="let matrixId of matrixIds | async"
					value="{{ matrixId.id }}"
				>
					{{ matrixId.id }}
				</option>
			</select>
		</div>

		<div class="form-group">
			<button type="submit" class="form-control btn btn-primary px-3">
				Run
			</button>
		</div>
	</form>
</div>

<div *ngIf="showResults">
	<div class="px-3 d-flex flex-column" style="margin-top: 30px; gap: 6px">
		<div class="d-flex justify-content-between">
			<h3 style="font-size: 22px; margin-bottom: 15px">
				{{ settlementInfo?.dfspName || "-" }}
			</h3>

			<button class="btn btn-primary" (click)="downloadDFSPSettlementDetailReport()">
				Export
			</button>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">Settlement ID</p>
			<p>{{ settlementInfo?.settlementId || "-" }}</p>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">Settlement Created Date</p>
			<p>{{ (settlementInfo?.settlementCreatedDate | momentDate) || "-" }}</p>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">DFSP ID</p>
			<p>{{ settlementInfo?.dfspId || "-" }}</p>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">DFSP Name</p>
			<p>{{ settlementInfo?.dfspName || "-" }}</p>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">Time Zone OffSet</p>
			<p>{{ '' | timezoneOffset }}</p>
		</div>
	</div>

	<table
		class="table mb-5"
		style="display: block; overflow-x: auto; margin-top: 30px"
	>
		<thead class="thead-light">
			<tr>
				<th scope="col" style="min-width: 160px">Sender DFSP ID</th>
				<th scope="col" style="min-width: 180px">Sender DFSP Name</th>
				<th scope="col" style="min-width: 160px">Receiver DFSP ID</th>
				<th scope="col" style="min-width: 190px">Receiver DFSP Name</th>
				<th scope="col" style="min-width: 200px">Transfer Id</th>
				<th scope="col" style="min-width: 160px">Tx Type</th>
				<th scope="col" style="min-width: 160px">Transaction Date</th>
				<th scope="col" style="min-width: 160px">Sender ID Type</th>
				<th scope="col" style="min-width: 160px">Sender ID</th>
				<th scope="col" style="min-width: 160px">Receiver ID Type</th>
				<th scope="col" style="min-width: 160px">Receiver ID</th>
				<th scope="col" style="min-width: 160px">Received Amount</th>
				<th scope="col" style="min-width: 160px">Sent Amount</th>
				<th scope="col" style="min-width: 160px">Fee</th>
				<th scope="col" style="min-width: 160px">Currency</th>
			</tr>
		</thead>

		<div *ngIf="(detailReports | async)?.length === 0">
			<p class="text-center my-5">No data available</p>
		</div>

		<tbody>
			<tr *ngFor="let detailReport of detailReports | async">
				<td style="min-width: 160px">{{ detailReport.payerFspId }}</td>
				<td style="min-width: 180px">
					{{ detailReport.payerParticipantName }}
				</td>
				<td style="min-width: 160px">{{ detailReport.payeeFspId }}</td>
				<td style="min-width: 190px">
					{{ detailReport.payeeParticipantName }}
				</td>
				<td style="min-width: 200px">{{ detailReport.transferId }}</td>
				<td style="min-width: 160px">
					{{ detailReport.transactionType }}
				</td>
				<td style="min-width: 160px">
					{{ detailReport.transactionDate | momentDate}}
				</td>
				<td style="min-width: 160px">{{ detailReport.payerIdType }}</td>
				<td style="min-width: 160px">
					{{ detailReport.payerIdentifier }}
				</td>
				<td style="min-width: 160px">{{ detailReport.payeeIdType }}</td>
				<td style="min-width: 160px">
					{{ detailReport.payeeIdentifier }}
				</td>
				<td style="min-width: 160px; text-align: right;">
					{{ detailReport.receivedAmount }}
				</td>
				<td style="min-width: 160px; text-align: right;">{{ detailReport.sentAmount }}</td>
				<td style="min-width: 160px; text-align: right;">-</td>
				<td style="min-width: 160px">{{ detailReport.currency }}</td>
			</tr>
		</tbody>
	</table>
</div>
