<div>
  <h2 class="mb-3">Registering new Oracle</h2>
</div>

<form [formGroup]="form">
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="inputName">Name</label>
      <input
        type="text"
        class="form-control"
        id="inputName"
        placeholder="Oracle Name"
        formControlName="name" />
      <div class="invalid-feedback">
        Please choose a valid name
      </div>
    </div>

  </div>

  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="inputPartyType">Party Type</label>
      <select
        id="inputPartyType"
        class="form-control"
        formControlName="partyType">
        <option value="MSISDN" [selected]="this.oracle.partyType==='MSISDN'">MSISDN</option>
        <option value="EMAIL" [selected]="this.oracle.partyType==='EMAIL'">Email</option>
        <option value="PERSONAL_ID" [selected]="this.oracle.partyType==='PERSONAL_ID'">Personal Id</option>
        <option value="BUSINESS" [selected]="this.oracle.partyType==='BUSINESS'">Business</option>
        <option value="DEVICE" [selected]="this.oracle.partyType==='DEVICE'">Device</option>
        <option value="ACCOUNT_ID" [selected]="this.oracle.partyType==='ACCOUNT_ID'">Account Id</option>
        <option value="IBAN" [selected]="this.oracle.partyType==='IBAN'">IBAN</option>
        <option value="ALIAS" [selected]="this.oracle.partyType==='ALIAS'">Alias</option>
      </select>
    </div>
    <div class="form-group col-md-6">
      <label for="inputType">Type</label>
      <select id="inputType" class="form-control" formControlName="type"
        (change)="selectOracleType($event)">
        <option value="builtin" [selected]="this.oracle.type==='builtin'">Built-in</option>
        <option value="remote-http" [selected]="this.oracle.type==='remote-http'">Remote HTTP(s)</option>
      </select>
    </div>
  </div>


  <div class="form-row">
    <div class="form-group col-md-12">
      <label for="inputEndpoint">Oracle endpoint</label>
      <input type="text" [readonly]="isBuiltinSelected"
        class="form-control"
        id="inputEndpoint"
        placeholder="Ex: https://oracle.example.com:443/"
        formControlName="endpoint"/>
      <small id="emailHelp" class="form-text text-muted">
        Required for types other than "Built-in". For oracles of type "Remote
        HTTP(s)" this is the complete URL where the Oracle API is available
      </small>
      <div class="invalid-feedback" >
        Invalid endpoint
      </div>
    </div>
  </div>

  <div class="form-row d-flex mb-3">
    <div class="p-2">
      <button type="submit" class="btn btn-primary" (click)="register()">Register Oracle</button>
    </div>
    <div class="ml-auto p-2">
      <button class="btn btn-danger" (click)="cancel()">Cancel</button>
    </div>
  </div>

</form>
