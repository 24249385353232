<h3>Settlement Matrices</h3>

<!--filter-->
<div class="filterbox">
	<form [formGroup]="filterForm" (ngSubmit)="search(0)">
		<table class="table">
			<tr class="form-row">
				<div class="form-group col-md-3">
					<label for="filterMatrixId">ID</label>
					<input type="text" formControlName="filterMatrixId" id="filterMatrixId" step=300
						class="form-control">
				</div>

				<div class="form-group col-md-3">
					<label for="filterMatrixType">Type</label>
					<select formControlName="filterMatrixType" id="filterMatrixType" class="form-control">
						<option value="{{ALL_STR_ID}}">{{ALL_STR_ID}}</option>
						<option *ngFor="let item of matrixTypeList" value="{{item}}">{{item}}</option>
					</select>
				</div>

				<div class="form-group col-md-3">
					<label for="filterMatrixState">State</label>
					<select formControlName="filterMatrixState" id="filterMatrixState" class="form-control">
						<option value="{{ALL_STR_ID}}">{{ALL_STR_ID}}</option>
						<option *ngFor="let item of matrixStateList" value="{{item}}">
							{{item}}
						</option>
					</select>
				</div>

				<div class="form-group col-md-3">
					<label for="filterMatrixModel">Model</label>
					<select formControlName="filterMatrixModel" id="filterMatrixModel" class="form-control">
						<option value="{{ALL_STR_ID}}">{{ALL_STR_ID}}</option>
						<option *ngFor="let item of matrixModelList" value="{{item}}">
							{{item}}
						</option>
					</select>
				</div>

				<div class="form-grofilterCurrencyup col-md-3">
					<label for="filterCurrency">Currency</label>
					<select formControlName="filterCurrency" id="filterCurrency" class="form-control">
						<option value="{{ALL_STR_ID}}">{{ALL_STR_ID}}</option>
						<option *ngFor="let item of (currencyCodeList | async)" value="{{item.code}}">
							{{item.code}}
						</option>
					</select>
				</div>

				<div class="form-group col-md-3">
					<label for="filterStartDate">Start Date</label>
					<input type="datetime-local" formControlName="filterStartDate" id="filterStartDate"
						class="form-control">
				</div>

				<div class="form-group col-md-3">
					<label for="filterEndDate">End Date</label>
					<input type="datetime-local" formControlName="filterEndDate" id="filterEndDate"
						class="form-control">
				</div>

				<div class="col-md-3"></div>

				<div class="col-md-9"></div>

				<div class="col-md-3 d-flex">
					<div class="form-group w-50">
						<label for="clearBtn">&nbsp;</label>
						<button type="button" class="form-control btn btn-outline-primary" id="clearBtn"
							(click)="clearFilters()">Clear</button>
					</div>

					<div class="form-group ml-3 w-50">
						<label for="filterBtn">&nbsp;</label>
						<button type="submit" class="form-control btn btn-primary" id="filterBtn">
							Search&nbsp;&nbsp;<i class="bi bi-search"></i>
						</button>
					</div>
				</div>
			</tr>
		</table>
	</form>
</div>

<div>
	<table class="table">
		<thead class="thead-light">
			<tr>
				<th scope="col">Id</th>
				<th scope="col">Type</th>
				<th scope="col">State</th>
				<th scope="col">Model</th>
				<th scope="col">Currency</th>
				<th scope="col">Batch Count</th>
				<th scope="col">Created at</th>
				<th scope="col">Updated at</th>
			</tr>
		</thead>
		<tbody>
			<ng-container *ngIf="(matrices | async)?.length==0">
				<tr class="">
					<td colspan="6">No matrices found</td>
				</tr>
			</ng-container>
			<tr *ngFor="let item of (matrices | async)">
				<td><a routerLink="/settlements/matrix/{{item.id}}">{{item.id}}</a></td>
				<td>{{item.type}}</td>
				<td>{{item.state}}</td>
				<td>{{item.settlementModel}}</td>
				<td>{{item.currencyCodes ? item.currencyCodes.join(", ") : "(All)"}}</td>

				<td>{{item.batches.length}}</td>
				<td>{{item.createdAt | momentDate}}</td>
				<td>{{item.updatedAt | momentDate}}</td>

			</tr>
		</tbody>
	</table>


	<!--pagination
	TODO: put in component
	-->
	<hr>
	<div class="list-page-navigation">
		<nav aria-label="Page navigation" *ngIf="paginateResult | async; let paginateObj">
		  <ul class="pagination justify-content-end">
			<li class="pagesize d-flex align-items-center">
			  <span>Items per page:</span>
			  <select (change)="search(0)" class="rows-selector form-control ml-2"
				  id="pageSize">
				<option value="5" [selected]="paginateObj.pageSize == 5">5</option>
				<option value="10" [selected]="paginateObj.pageSize == 10">10</option>
				<option value="25" [selected]="paginateObj.pageSize == 25">25</option>
				<option value="50" [selected]="paginateObj.pageSize == 50">50</option>
				<option value="100" [selected]="paginateObj.pageSize == 100">100</option>
			  </select>
			</li>

			<li class="gotopage d-flex align-items-center">
			  <span >Go to page</span>
			  <select (change)="search()" class="rows-selector form-control ml-2" id="pageIndex">
				<option *ngFor="let item of [].constructor(paginateObj.totalPages); let i = index" [value]="i"
					[selected]="paginateObj.current == i+1">{{i+1}}</option>
			  </select>

			</li>

			<li class="d-flex align-items-center">
			  <span>Page {{paginateObj.current}} of {{paginateObj.totalPages}}</span>
			</li>

			<!-- first-->
			<li [ngClass]="{'disabled' : paginateObj.prev == null}">
			  <a class="page-link" aria-label="Previous" (click)="search(0)">
				<span aria-hidden="true">&lt;&lt;</span>
				<span class="sr-only">First</span>
			  </a>
			</li>

			<!-- previous-->
			<li [ngClass]="{'disabled' : paginateObj.prev == null}">
			  <a class="page-link" aria-label="Previous" (click)="search(paginateObj.prev ? paginateObj.prev-1 : 0)">
				<span aria-hidden="true">&lt;</span>
				<span class="sr-only">Previous</span>
			  </a>
			</li>

			<!-- next-->
			<li [ngClass]="{'disabled' : paginateObj.next == null}">
			  <a class="page-link" aria-label="Next" (click)="search(paginateObj.next ? paginateObj.next-1 : 0)">
				<span aria-hidden="true">&gt;</span>
				<span class="sr-only">Next</span>
			  </a>
			</li>

			<!-- last-->
			<li [ngClass]="{'disabled' : paginateObj.next == null}">
			  <a class="page-link" aria-label="Next" (click)="search(paginateObj.totalPages-1)">
				<span aria-hidden="true">&gt;&gt;</span>
				<span class="sr-only">Last</span>
			  </a>
			</li>
		  </ul>
		</nav>
	</div>
</div>
