<div>
  <h2 class="mb-3">Registering new Oracle Participant Association</h2>
</div>

<form [formGroup]="form">
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="inputPartyId">Party ID</label>
      <input
        type="text"
        class="form-control"
        id="inputPartyId"
        placeholder="Party ID"
        formControlName="partyId" />
      <div class="invalid-feedback">
        Please choose a valid fspId
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="inputPartyType">Party Type</label>
      <select
        id="inputPartyType"
        class="form-control"
        formControlName="partyType">
        <option value="" disabled selected>Select a Party Type</option>
        <option *ngFor="let partyType of partyTypeList" [value]="partyType">{{partyType}}</option>
      </select>    
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="inputCurrency">Type</label>
      <select
        id="inputCurrency"
        class="form-control"
        formControlName="currency"
        (change)="selectCurrency($event)">
        <option value="" disabled selected>Select a Currency</option>
        <option *ngFor="let currencyCode of currencyList" [value]="currencyCode">{{currencyCode}}</option>
      </select>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="inputParticipantId">Participant Id</label>
      <select
        id="inputParticipantId"
        class="form-control"
        formControlName="participantId">
        <option value="" disabled selected>Select a Participant</option>
        <option *ngFor="let item of (participants | async)" [value]="item.id">{{item.name}}</option>
      </select>
    </div>
  </div>
  
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="inputSubType">Party SubType</label>
      <input
        type="text"
        class="form-control"
        id="inputSubType"
        placeholder="Party SubType"
        formControlName="subType" />
    </div>
  </div>

  <div class="form-row d-flex mb-3">
    <div class="p-2">
      <button type="submit" class="btn btn-primary" (click)="register()">Register Participant Oracle Association</button>
    </div>
    <div class="ml-auto p-2">
      <button class="btn btn-danger" (click)="cancel()">Cancel</button>
    </div>
  </div>

</form>
