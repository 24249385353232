<h2>Create Test Transfer <span class="text-secondary">(dev mode enable)</span></h2>
<hr>

<form [formGroup]="form" (ngSubmit)="saveTransfer()">
	<ng-container *ngIf="inputQuoteId === null">
		<ng-container *ngIf="(quotes | async)?.length==0 else listQuotes">
			<div class="alert alert-warning" role="alert">
				No Quotes found, please create a one.
			</div>
		</ng-container>

		<ng-template #listQuotes>
			<h3>Apply existing Quote values</h3>
			<label class="my-1 mr-2" for="selectedQuoteId">Quote Id</label>
			<select class="custom-select my-1 mr-sm-4" id="selectedQuoteId" formControlName="selectedQuoteId">
				<option *ngFor="let item of (quotes | async)" value="{{item.quoteId}}">{{item.quoteId}}
					- {{item.createdAt | momentDate}}</option>
			</select>
			<div class="ml-auto p-2">
				<button class="btn btn-primary" type="button" (click)="applyQuote()">Apply</button>
			</div>

		</ng-template>
	</ng-container>

	<div class="form-row mt-4"> <!--form-row-->

		<!--<div class="form-group col-md-12">
			<h4>Create a new Transfer  (dev mode enable)</h4>
		</div>-->

		<!--transferId-->
		<div class="form-group col-md-12">
			<div class="row">
				<div class="form-group col-md-11">
					<label for="transferId">Transfer Id:</label>
					<input id="transferId" class="form-control" formControlName="transferId" type="text">
				</div>
				<div class="form-group col-md-1">
					<label for="genNewIdBtn">&nbsp;</label>
					<button class="form-control btn btn-primary" type="button" (click)="genNewId()" id="genNewIdBtn"><i
						class="bi bi-arrow-clockwise"></i></button>
				</div>
			</div>

		</div>


		<!--payerFsp-->
		<div class="form-group col-md-4">
			<label for="payerFsp">Payer Fsp:</label>
			<select id="payerFsp" class="form-control" formControlName="payerFsp">
				<option *ngFor="let participant of (participants | async)"
						value="{{participant.id}}">{{participant.name}}</option>
			</select>
		</div>

		<!--payeeFsp-->
		<div class="form-group col-md-4">
			<label for="payeeFsp">Payee Fsp:</label>
			<select id="payeeFsp" class="form-control" formControlName="payeeFsp">
				<option *ngFor="let participant of (participants | async)"
						value="{{participant.id}}">{{participant.name}}</option>
			</select>
		</div>


		<!--amount-->
		<div class="form-group col-md-2">
			<label for="currency">Currency:</label>
			<select id="currency" class="form-control" formControlName="currency">
				<option *ngFor="let currency of (currencyCodeList | async)" value="{{currency.code}}">{{currency.code}}</option>
			</select>
		</div>
		<div class="form-group col-md-2">
			<label for="amount">Amount:</label>
			<input class="form-control" id="amount" formControlName="amount" type="text"
				   [ngClass]="{'is-valid': form.controls['amount'].touched && form.controls['amount'].valid,
              'is-invalid': (submitted || form.controls['amount'].touched) && form.controls['amount'].invalid}">
		</div>

		<!--ilpPacket-->
		<div class="form-group col-md-12">
			<label for="ilpPacket">ILP Packet:</label>
			<input id="ilpPacket" class="form-control" formControlName="ilpPacket" type="text">
		</div>

		<!--condition-->
		<div class="form-group col-md-12">
			<label for="condition">Condition:</label>
			<input id="condition" class="form-control" formControlName="condition" type="text">
		</div>

		<!--expiration-->
		<div class="form-group col-md-12">
			<div class="row">
				<div class="form-group col-md-11">
					<label for="expiration">Expiration:</label>
					<input id="expiration" class="form-control" formControlName="expiration" type="text">
				</div>
				<div class="form-group col-md-1">
					<label for="genNewExpirationBtn">&nbsp;</label>
					<button class="form-control btn btn-primary" type="button" (click)="genNewExpiration()"
							id="genNewExpirationBtn"><i class="bi bi-arrow-clockwise"></i></button>
				</div>
			</div>
		</div>

	</div> <!--form-row-->

	<div class="form-row d-flex mb-3">
		<div class="p-2">
			<button type="submit" class="btn btn-primary" [disabled]="!form.valid">Create Transfer</button>
		</div>
		<div class="ml-auto p-2">
			<button class="btn btn-danger" (click)="cancel()">Cancel</button>
		</div>
	</div>
</form>
