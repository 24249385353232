<h2 class="mb-4">DFSP Settlement Report</h2>

<div class="filterbox">
	<form
		[formGroup]="dfspFilterForm"
		(ngSubmit)="searchSettlementIds()"
		class="d-flex justify-content-between align-items-end mx-3"
	>
		<div class="d-flex" style="gap: 30px">
			<div class="form-group" style="width: 250px">
				<label for="dfspName">DFSP Name:</label>
				<select
					id="dfspName"
					formControlName="dfspName"
					class="form-control"
					required
					aria-required="true"
				>
					<option value="">Choose DFSP</option>
					<option
						*ngFor="let participant of participants | async"
						value="{{ participant.id }}"
					>
						{{ participant.name }}
					</option>
				</select>
			</div>

			<div class="form-group" style="width: 250px">
				<label for="startDate">Start Date:</label>
				<input
					id="startDate"
					type="datetime-local"
					formControlName="startDate"
					class="form-control"
					required
					aria-required="true"
				/>
			</div>

			<div class="form-group" style="width: 250px">
				<label for="endDate">End Date:</label>
				<input
					id="endDate"
					type="datetime-local"
					formControlName="endDate"
					class="form-control"
					required
					aria-required="true"
				/>
			</div>
		</div>

		<div class="form-group">
			<button
				type="submit"
				class="form-control btn btn-primary d-flex align-items-center px-3"
			>
				Search<i class="bi bi-search ml-2" style="font-size: 14px"></i>
			</button>
		</div>
	</form>

	<form
		*ngIf="showSettlementIdForm"
		[formGroup]="settlementIdForm"
		(ngSubmit)="searchReports()"
		class="d-flex align-items-end mx-3"
		style="gap: 30px"
	>
		<div class="form-group" style="width: 250px">
			<label for="settlementId">Settlement ID:</label>
			<select
				id="settlementId"
				formControlName="settlementId"
				class="form-control"
				required
				aria-required="true"
				(change)="onSettlementIdChange()"
			>
				<option value="">Choose Settlement ID</option>
				<option
					*ngFor="let matrixId of matrixIds | async"
					value="{{ matrixId.id }}"
				>
					{{ matrixId.id }}
				</option>
			</select>
		</div>

		<div class="form-group">
			<button type="submit" class="form-control btn btn-primary px-3">
				Run
			</button>
		</div>
	</form>
</div>

<div *ngIf="showResults">
	<div class="px-3 d-flex flex-column" style="margin-top: 30px; gap: 6px">
		<div class="d-flex justify-content-between">
			<h3 style="font-size: 22px; margin-bottom: 15px">
				{{ settlementInfo?.dfspName || "-" }}
			</h3>

			<button class="btn btn-primary" (click)="downloadDFSPSettlementReport()">
				Export
			</button>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">Settlement ID</p>
			<p>{{ settlementInfo?.settlementId || "-" }}</p>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">Settlement Created Date</p>
			<p>{{ (settlementInfo?.settlementCreatedDate | momentDate) || "-" }}</p>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">DFSP ID</p>
			<p>{{ settlementInfo?.dfspId || "-" }}</p>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">DFSP Name</p>
			<p>{{ settlementInfo?.dfspName || "-" }}</p>
		</div>

		<div class="d-flex">
			<p style="min-width: 250px">Time Zone OffSet</p>
			<p>{{ '' | timezoneOffset }}</p>
		</div>
	</div>

	<table
		class="table mb-0"
		style="overflow-x: auto; margin-top: 30px"
	>
		<thead class="thead-light">
			<tr>
				<th scope="col" style="min-width: 160px">DFSP ID</th>
				<th scope="col" style="min-width: 160px">DFSP Name</th>
				<th scope="col" style="min-width: 160px">Sent to FSP Volume</th>
				<th scope="col" style="min-width: 160px">Sent to FSP Value</th>
				<th scope="col" style="min-width: 160px">
					Received from FSP Volume
				</th>
				<th scope="col" style="min-width: 160px">
					Received from FSP Value
				</th>
				<th scope="col" style="min-width: 160px">
					Total Transaction Volume
				</th>
				<th scope="col" style="min-width: 160px">
					Total Value of All Transactions
				</th>
				<th scope="col" style="min-width: 160px">
					Net Position vs. Each DFSP
				</th>
				<th scope="col" style="min-width: 160px">Currency</th>
			</tr>
		</thead>

		<div *ngIf="(reports | async)?.length === 0">
			<p class="text-center my-5">No data available</p>
		</div>

		<tbody>
			<tr *ngFor="let report of reports | async">
				<td style="min-width: 160px">
					{{ report.relateParticipantId }}
				</td>
				<td style="min-width: 160px">
					{{ report.relateParticipantName }}
				</td>
				<td style="min-width: 160px; text-align: right;">{{ report.totalSentCount }}</td>
				<td style="min-width: 160px; text-align: right;">{{ report.totalAmountSent }}</td>
				<td style="min-width: 160px; text-align: right;">
					{{ report.totalReceivedCount }}
				</td>
				<td style="min-width: 160px; text-align: right;">
					{{ report.totalAmountReceived }}
				</td>
				<td style="min-width: 160px; text-align: right;">
					{{ report.totalTransactionCount }}
				</td>
				<td style="min-width: 160px; text-align: right;">{{ report.totalAmount }}</td>
				<td style="min-width: 160px; text-align: right;">{{ report.netPosition }}</td>
				<td style="min-width: 160px">{{ report.currency }}</td>
			</tr>
		</tbody>
	</table>

	<div class="border-top border-bottom mb-5">
		<table class="table mb-0" style="display: block; overflow-x: auto; margin-top: 30px">
			<thead class="thead-light">
				<tr>
					<th colspan="2">Aggregated Net Positions</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let netPosition of aggregatedNetPositions">
					<td>
						{{ netPosition.currencyCode }}
					</td>
					<td style="text-align: right;">
						{{ netPosition.value }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
