<div class="row mb-2">
  <div class="col">
    <h3>Pending Approval Requests</h3>
  </div>
</div>

<ul ngbNav #nav="ngbNav" class="mt-4 nav-tabs" (navChange)="tabChange($event)">
  <li ngbNavItem="fundAdjustment">
    <!-- fundAdjustment -->
    <a ngbNavLink
      >Fund Adjustment
      <span class="ml-2 badge bg-danger text-white">{{
        fundAdjustmentCount
      }}</span></a
    >
    <ng-template ngbNavContent>
      <h4 class="mt-3">Pending Fund Adjustment for all Participants</h4>
      <div>
        <button
          class="btn btn-success"
          (click)="approveFundAdjustmentPendingApprovals()"
          [disabled]="
            !isFundAdjustmentSelectAll && selectedFundAdjustment.length === 0
          "
        >
          Approve
        </button>
        <button
          class="btn btn-danger ml-2"
          (click)="rejectFundAdjustmentPendingApprovals()"
          [disabled]="
            !isFundAdjustmentSelectAll && selectedFundAdjustment.length === 0
          "
        >
          Reject
        </button>
      </div>
      <table class="table table-hover mt-3">
        <thead class="thead-light">
          <tr>
            <th scope="col">
              <input
                [checked]="isFundAdjustmentSelectAll"
                type="checkbox"
                (change)="selectAllFundAdjustments($event)"
              />
            </th>
            <th scope="col">Request Id</th>
            <th scope="col">Participant Name</th>
            <th scope="col">Activity</th>
            <th scope="col">Amount</th>
            <th scope="col">Currency</th>
            <th scope="col">Request By</th>
            <th scope="col">Request Date Time</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of fundAdjustments | async">
            <td>
              <input
                [checked]="
                  isFundAdjustmentSelectAll || isSelectedFundAdjustment(item.id)
                "
                type="checkbox"
                (change)="selectFundAdjustment($event, item)"
              />
            </td>
            <td>{{ item.id }}</td>
            <td>
              <a routerLink="/participants/{{ item.participantId }}">{{
                item.participantName
              }}</a>
            </td>
            <td>{{ item.type }}</td>
            <td style="text-align: right;">{{ formatCommaSeparator(item.amount) }}</td>
            <td>{{ item.currencyCode }}</td>
            <td>{{ item.createdBy }}</td>
            <td>{{ item.createdDate | momentDate }}</td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </li>
  <li ngbNavItem="ndc">
    <!-- ndc -->
    <a ngbNavLink
      >NDC
      <span class="ml-2 badge bg-danger text-white">{{
        ndcRequestCount
      }}</span></a
    >
    <ng-template ngbNavContent>
      <h4 class="mt-3">Pending NDC for all Participants</h4>
      <div>
        <button
          class="btn btn-success"
          (click)="approveNDCRequestPendingApprovals()"
          [disabled]="!isNDCSelectAll && selectedNDCRequest.length === 0"
        >
          Approve
        </button>
        <button
          class="btn btn-danger ml-2"
          (click)="rejectNDCRequestPendingApprovals()"
          [disabled]="!isNDCSelectAll && selectedNDCRequest.length === 0"
        >
          Reject
        </button>
      </div>
      <table class="table table-hover mt-3">
        <thead class="thead-light">
          <tr>
            <th scope="col">
              <input
                [checked]="isNDCSelectAll"
                type="checkbox"
                (change)="selectAllNDCRequests($event)"
              />
            </th>
            <th scope="col">Request Id</th>
            <th scope="col">Participant Name</th>
            <th scope="col">Type</th>
            <th scope="col">Amount</th>
            <th scope="col">Percentage</th>
            <th scope="col">Currency</th>
            <th scope="col">Created At</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of ndcRequests | async">
            <td>
              <input
                [checked]="isNDCSelectAll || isSelectedNDCRequest(item.id)"
                type="checkbox"
                (change)="selectNDCRequests($event, item)"
              />
            </td>
            <td>{{ item.id }}</td>
            <td>
              <a routerLink="/participants/{{ item.participantId }}">{{
                item.participantName
              }}</a>
            </td>
            <td>{{ item.type }}</td>
            <td style="text-align: right;">{{ formatCommaSeparator(item.fixedValue) }}</td>
            <td>{{ item.percentage ?? "-" }}</td>
            <td>{{ item.currencyCode }}</td>
            <td>{{ item.createdDate | momentDate }}</td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </li>
		<!-- Certificates -->
  <li ngbNavItem="certificates">
	  <a ngbNavLink>Certificates
      <span class="ml-2 badge bg-danger text-white">{{
		pendingCertificates.length
      }}</span></a
    >

	<ng-template ngbNavContent>
	  <h4 class="mt-3">Pending Certificates for Approval</h4>
	  <div>
		  <button
			class="btn btn-success"
			(click)="approveCertificates()"
			[disabled]="selectedCertificates.length === 0"
			>
			Approve
		  </button>
		  <button
			class="btn btn-danger ml-2"
			(click)="rejectCertificates()"
			[disabled]="selectedCertificates.length === 0"
			>
			Reject
		  </button>
		</div>
		  <table class="table table-hover mt-3">
			  <thead class="thead-light">
				  <tr>
					  <th scope="col">
						<input
						  [checked]="isCertificateSelectAll"
						  type="checkbox"
						  (change)="selectAllCertificates($event)"
						/>
					  </th>
					  <th scope="col">Request ID</th>
					  <th scope="col">Participant ID</th>
					  <th scope="col">Subject</th>
					  <th scope="col">Issued By</th>
					  <th scope="col">Valid From</th>
					  <th scope="col">Valid To</th>
					  <th scope="col">Uploaded By</th>
					  <!-- <th scope="col">Actions</th> -->
				  </tr>
			  </thead>
			  <tbody>
				<tr *ngFor="let certificate of pendingCertificates">
					<td>
						<input
							[checked]="isCertificateSelectAll || isCertificateSelected(certificate)"
							type="checkbox"
							(change)="selectCertificate($event, certificate)"
						/>
					</td>
					<td>{{ certificate._id }}</td>
					<td>{{ certificate.participantId }}</td>
					<td>{{ certificate.certInfo?.subject }}</td>
					<td>{{ certificate.certInfo?.issuer }}</td>
					<td>
						{{ certificate.certInfo?.validFrom | momentDate }}
					</td>
					<td>
						{{ certificate.certInfo?.validTo | momentDate }}
					</td>
					<td>{{ certificate.createdBy }}</td>
				</tr>
			</tbody>
		</table>
	</ng-template>
</li>
	<!-- Certificates -->
</ul>

<div class="mt-6" [ngbNavOutlet]="nav"></div>
