<h3>Settlement Batches</h3>

<!--filter-->
<div class="list-page-filterbox">
	<form >
	<table class="table">
		<tr class="form-row">
			<div class="form-group col-md-2">
				<label for="criteriaSettlementModel">Model:</label>
				<select class="form-control" id="criteriaSettlementModel" [disabled]="isDisabled">
					<option value="DEFAULT">DEFAULT</option>
				</select>
			</div>


			<div class="form-group col-md-1">
				<label for="criteriaCurrencyCode">Currency:</label>
				<select class="form-control" id="criteriaCurrencyCode" [ngModel]="criteriaCurrencyCode"
					[disabled]="isDisabled" [ngModelOptions]="{standalone: true}">
					<option value="{{ALL_STR_ID}}">{{ALL_STR_ID}}</option>
					<option *ngFor="let item of (currencyCodeList | async)" value="{{item.code}}">
						{{item.code}}
					</option>
				</select>
			</div>

			<div class="form-group col-md-2">
				<label for="criteriaFromDate">Start:</label>
				<input class="form-control" type="datetime-local" id="criteriaFromDate" [disabled]="isDisabled"
					   [ngModel]="criteriaFromDate" step=300
					   [ngModelOptions]="{standalone: true}">
			</div>


			<div class="form-group col-md-2">
				<label for="criteriaToDate">End:</label>
				<input class="form-control" type="datetime-local" id="criteriaToDate" [disabled]="isDisabled"
					   [ngModel]="criteriaToDate" step=300
					   [ngModelOptions]="{standalone: true}">
			</div>

<!--			<div class="form-group col-md-1">
				<label for="criteriaToDate">Settled?</label>
				<input class="form-control" type="checkbox" id="criteriaIncludeSettled"
					   [ngModel]="criteriaIncludeSettled" [ngModelOptions]="{standalone: true}">
			</div>-->

			<div class="form-group col-md-2">
				<label for="criteriaBatchState">Batch State:</label>
				<select class="form-control" id="criteriaBatchState" [disabled]="isDisabled">
					<option value="{{ALL_STR_ID}}">{{ALL_STR_ID}}</option>
					<option value="OPEN">OPEN</option>
					<option value="CLOSED">CLOSED</option>
					<option value="DISPUTED">DISPUTED</option>
					<option value="AWAITING_SETTLEMENT">AWAITING_SETTLEMENT</option>
					<option value="SETTLED">SETTLED</option>
				</select>
			</div>

			<div class="form-group col-md-3">
				<label for="criteriaToDate">Batch Id</label>
				<input class="form-control" type="text" id="criteriaBatchId" (input)="onTextChange()"
					[(ngModel)]="criteriaBatchId" [ngModelOptions]="{standalone: true}">
			</div>
		</tr>
		<tr class="form-row">
			<div class="form-group col-md-10">
				&nbsp;
			</div>
			<div class="form-group col-md-2 ">
				<label for="filterBtn">&nbsp;</label>
					<button type="submit" class="form-control btn btn-primary" id="filterBtn"
						(click)="applyCriteria(0)">Search&nbsp;&nbsp;<i class="bi bi-search"></i></button>
			</div>

		</tr>
	</table>
	</form>
</div>

<form>
	<tr class="form-row" >
		<div class="col-md-8">&nbsp;</div>
		<div class="form-group col-md-2">
			<label for="createDynamicMatrixBtn">&nbsp;</label>
			<button type="button" class="form-control btn btn-success" id="createDynamicMatrixBtn"
					[disabled]="!batches || (batches | async)?.length==0 || selectedBatchIds.length>0"
					(click)="createDynamicMatrix()"
					ngbTooltip="Create a Dynamic Settlement Matrix using the selected criteria">Create Dynamic Matrix
			</button>
		</div>
		<div class="form-group col-md-2">
			<label for="createStaticMatrixBtn">&nbsp;</label>
			<button type="button" class="form-control btn btn-success" id="createStaticMatrixBtn"
					[disabled]="selectedBatchIds.length<=0"
					(click)="createStaticMatrix()"
					ngbTooltip="Create a Static Settlement Matrix using the selected criteria">Create Static Matrix
			</button>
		</div>
	</tr>
</form>


<div>
	<table class="table">
		<thead class="thead-light">
		<tr>
			<th scope="col">Sel?</th>
			<th scope="col">Batch ID</th>
			<th scope="col">Model</th>
			<th scope="col">Currency</th>
			<th scope="col">Created</th>
			<th scope="col">Seq#</th>
			<th scope="col">Name</th>
			<th scope="col">State</th>
		</tr>
		</thead>
		<tbody>
		<ng-container *ngIf="(batches | async)?.length==0">
			<tr class="" >
				<td colspan="6">No batches found, please try again with different criteria</td>
			</tr>
		</ng-container>
		<tr *ngFor="let item of (batches | async)">
			<td>
				<input type="checkbox" class="form-control" *ngIf="item.state!=='SETTLED'"
					   (change)="batchSelectionChanged($event)" [id]="batchSelPrefix+item.id">
			</td>
			<td><a (click)="selectBatch(0, undefined, item.id);" class="link">{{item.id}}</a></td>
			<td>{{item.settlementModel}}</td>
			<td>{{item.currencyCode}}</td>
			<td>{{item.timestamp | momentDate}}</td>
			<td>{{item.batchSequence}}</td>
			<td>{{item.batchName}}</td>
			<td>{{item.state}}</td>

		</tr>
		</tbody>
	</table>
</div>


<hr>
<div class="list-page-navigation">
<nav aria-label="Page navigation" *ngIf="paginateResult | async; let paginateObj">
	<ul class="pagination justify-content-end">
		<li class="pagesize d-flex align-items-center">
			<span>Items per page:</span>
			<select (change)="applyCriteria(0)" class="rows-selector form-control ml-2"
					id="pageSize">
				<option value="5" [selected]="paginateObj.pageSize == 5">5</option>
				<option value="10" [selected]="paginateObj.pageSize == 10">10</option>
				<option value="25" [selected]="paginateObj.pageSize == 25">25</option>
				<option value="50" [selected]="paginateObj.pageSize == 50">50</option>
				<option value="100" [selected]="paginateObj.pageSize == 100">100</option>
			</select>
		</li>

		<li class="gotopage d-flex align-items-center">
			<span >Go to page</span>
			<select (change)="applyCriteria()" class="rows-selector form-control ml-2" id="pageIndex">
				<option *ngFor="let item of [].constructor(paginateObj.totalPages); let i = index" [value]="i"
						[selected]="paginateObj.current == i+1">{{i+1}}</option>
			</select>

		</li>

		<li class="d-flex align-items-center">
			<span>Page {{paginateObj.current}} of {{paginateObj.totalPages}}</span>
		</li>

		<!-- first-->
		<li [ngClass]="{'disabled' : paginateObj.prev == null}">
			<a class="page-link" aria-label="Previous" (click)="applyCriteria(0)">
				<span aria-hidden="true">&lt;&lt;</span>
				<span class="sr-only">First</span>
			</a>
		</li>

		<!-- previous-->
		<li [ngClass]="{'disabled' : paginateObj.prev == null}">
			<a class="page-link" aria-label="Previous" (click)="applyCriteria(paginateObj.prev ? paginateObj.prev-1 : 0)">
				<span aria-hidden="true">&lt;</span>
				<span class="sr-only">Previous</span>
			</a>
		</li>

		<!-- next-->
		<li [ngClass]="{'disabled' : paginateObj.next == null}">
			<a class="page-link" aria-label="Next" (click)="applyCriteria(paginateObj.next ? paginateObj.next-1 : 0)">
				<span aria-hidden="true">&gt;</span>
				<span class="sr-only">Next</span>
			</a>
		</li>

		<!-- last-->
		<li [ngClass]="{'disabled' : paginateObj.next == null}">
			<a class="page-link" aria-label="Next" (click)="applyCriteria(paginateObj.totalPages-1)">
				<span aria-hidden="true">&gt;&gt;</span>
				<span class="sr-only">Last</span>
			</a>
		</li>
	</ul>
</nav>
</div>


<h3>Transfers</h3>

<div>
	<table class="table">
		<thead class="thead-light">
		<tr>
			<th scope="col">Id</th>
			<th scope="col">Date</th>
			<th scope="col">PayerFspId</th>
			<th scope="col">PayeeFspId</th>
			<th scope="col">Amount</th>
			<th scope="col">Currency</th>
		</tr>
		</thead>
		<tbody>
		<ng-container *ngIf="(batchTransfers | async)?.length==0">
			<tr class="">
				<td colspan="6">Please select a batch to see its transfers</td>
			</tr>
		</ng-container>
		<tr *ngFor="let item of (batchTransfers | async)">
			<td><a routerLink="/transfers/{{item.transferId}}">{{item.transferId}}</a></td>
			<td>{{item.transferTimestamp | momentDate}}</td>
			<td>{{item.payerFspId}}</td>
			<td>{{item.payeeFspId}}</td>
			<td style="text-align: right;">{{ formatCommaSeparator(item.amount) }}</td>
			<td>{{item.currencyCode}}</td>

		</tr>
		</tbody>
	</table>
</div>


<hr>
<div class="list-page-navigation">
<nav aria-label="Page navigation" *ngIf="paginateTrfResult | async; let paginateObj">
	<ul class="pagination justify-content-end">
		<li class="pagesize d-flex align-items-center">
			<span>Items per page:</span>
			<select (change)="selectBatch(0)" class="rows-selector form-control ml-2"
					id="pageSizeTrf">
				<option value="5" [selected]="paginateObj.pageSize == 5">5</option>
				<option value="10" [selected]="paginateObj.pageSize == 10">10</option>
				<option value="25" [selected]="paginateObj.pageSize == 25">25</option>
				<option value="50" [selected]="paginateObj.pageSize == 50">50</option>
				<option value="100" [selected]="paginateObj.pageSize == 100">100</option>
			</select>
		</li>

		<li class="gotopage d-flex align-items-center">
			<span >Go to page</span>
			<select (change)="selectBatch()" class="rows-selector form-control ml-2" id="pageIndexTrf">
				<option *ngFor="let item of [].constructor(paginateObj.totalPages); let i = index" [value]="i"
						[selected]="paginateObj.current == i+1">{{i+1}}</option>
			</select>

		</li>

		<li class="d-flex align-items-center">
			<span>Page {{paginateObj.current}} of {{paginateObj.totalPages}}</span>
		</li>

		<!-- first-->
		<li [ngClass]="{'disabled' : paginateObj.prev == null}">
			<a class="page-link" aria-label="Previous" (click)="selectBatch(0)">
				<span aria-hidden="true">&lt;&lt;</span>
				<span class="sr-only">First</span>
			</a>
		</li>

		<!-- previous-->
		<li [ngClass]="{'disabled' : paginateObj.prev == null}">
			<a class="page-link" aria-label="Previous" (click)="selectBatch(paginateObj.prev ? paginateObj.prev-1 : 0)">
				<span aria-hidden="true">&lt;</span>
				<span class="sr-only">Previous</span>
			</a>
		</li>

		<!-- next-->
		<li [ngClass]="{'disabled' : paginateObj.next == null}">
			<a class="page-link" aria-label="Next" (click)="selectBatch(paginateObj.next ? paginateObj.next-1 : 0)">
				<span aria-hidden="true">&gt;</span>
				<span class="sr-only">Next</span>
			</a>
		</li>

		<!-- last-->
		<li [ngClass]="{'disabled' : paginateObj.next == null}">
			<a class="page-link" aria-label="Next" (click)="selectBatch(paginateObj.totalPages-1)">
				<span aria-hidden="true">&gt;&gt;</span>
				<span class="sr-only">Last</span>
			</a>
		</li>
	</ul>
</nav>
</div>
