<div class="row justify-content-center align-items-center mt-4">
	<div class="col-6">

		<form [formGroup]="form" class="col-12">
			<h3>Login</h3>
			<hr>
			<div class="form-group">
				<label for="username">Email / Username:</label>
				<input class="form-control" id="username" formControlName="username" type="text">
			</div>

			<div class="form-group">
				<label for="password">Password: </label>
				<input class="form-control" id="password" formControlName="password" type="password">
			</div>

			<hr>

			<button class="btn btn-primary" (click)="login()">Login</button>
		</form>
	</div>
</div>
