<div class="row">
  <div class="col-md-8 align-self-center">
    <h2>Oracle Details</h2>
  </div>

  <div class="col-md-4 align-self-center">
    <small><strong>ID: </strong> {{(oracle | async)?.id}}
      <a (click)="copyOracleIdToClipboard()"
         ngbTooltip="Copy ID to clipboard"><i class="bi bi-clipboard-plus"></i></a></small>
  </div>
</div>
<hr>

<div class="row">
  <div class="col-6"> <!-- left col with form -->

    <div class="mb-2">
      <label class="col-form-label text-secondary">Name</label>
      <div>{{(oracle | async)?.name ? (oracle | async)?.name : "N/A" }}</div>
    </div>

    <div class="mb-2">
      <label class="col-form-label text-secondary">Type</label>
      <div>{{(oracle | async)?.type}}</div>
    </div>

  </div> <!-- left col with form -->

  <div class="col-6"> <!-- right col with action buttons -->

    <div class="mb-2">
      <label class="col-form-label text-secondary">Endpoint</label>
      <div>{{(oracle | async)?.endpoint ? (oracle | async)?.endpoint : "N/A" }}</div>
    </div>

    <div class="mb-2">
      <label class="col-form-label text-secondary">Party Type</label>
      <div>{{(oracle | async)?.partyType}}</div>
    </div>

  </div> <!-- right col with action buttons -->

  <div class="col-3 mt-4">
    <ng-container >

        <button class="btn btn-primary btn-block" (click)="healthCheck(oracle)">
          Health Check
        </button>

    </ng-container>

  </div> <!-- right col with action buttons -->
</div>
