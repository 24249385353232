<div class=" row"> <!--	Top header-->
	<div class="col-md-8 align-self-center">
		<h2 *ngIf="(matrix | async)?.state!=='OUT_OF_SYNC'">Matrix Details - <span  [ngClass]="{'text-success' : (matrix | async)?.state === 'FINALIZED'}">{{(matrix | async)?.state}}</span></h2>
		<h2 *ngIf="(matrix | async)?.state==='OUT_OF_SYNC'">Matrix Details <span class="text-danger"> - {{(matrix | async)?.state}} (please recalculate<i class="bi bi-exclamation-triangle-fill"></i>)</span></h2>
	</div>

	<div class="col-md-4 align-self-center">
		<small><strong>ID: </strong>  {{(matrix | async)?.id}}<a (click)="copyIdToClipboard()" ngbTooltip="Copy ID to clipboard" class="ml-1 text-dark"><i class="bi bi-clipboard-plus"></i></a></small>
	</div>
</div> <!--	Top header-->

<hr>


<div class="row">
	<div class="col-10"> <!-- left col with form -->
		<div class="row">
			<div class="col-4">
				<div class="mb-2">
					<label class="col-form-label text-secondary">State</label>
					<div>{{(matrix | async)?.state}}

						<!--<span class="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"
							  *ngIf="(matrix | async)?.state === 'BUSY'"></span>-->
					</div>
				</div>
			</div>

			<div class="col-4">
				<div class="mb-2">
					<label class="col-form-label text-secondary">Matrix Type</label>
					<div>{{(matrix | async)?.type}}</div>
				</div>
			</div>

			<div class="col-4">
				<div class="mb-2">
					<label class="col-form-label text-secondary">Settlement Model</label>
					<div>{{(matrix | async)?.settlementModel}}</div>
				</div>
			</div>

		</div>

		<div class="row">
			<div class="col-4">
				<div class="mb-2">
					<label class="col-form-label text-secondary">Currencies</label>
					<ng-container *ngIf="!(matrix | async)?.currencyCodes || (matrix | async)?.currencyCodes?.length===0; else currencyList">
						(All)
					</ng-container>

					<ng-template #currencyList>
						<div>{{(matrix | async)?.currencyCodes?.join(",")}}</div>
					</ng-template>
				</div>
			</div>
			<div class="col-4">
				<div class="mb-2">
					<label class="col-form-label text-secondary">Start Date</label>
					<div>{{(matrix | async)?.dateFrom | momentDate}} </div>
				</div>
			</div>
			<div class="col-4">
				<div class="mb-2">
					<label class="col-form-label text-secondary">End Date</label>
					<div>{{(matrix | async)?.dateTo | momentDate}} </div>
				</div>
			</div>

		</div>

		<div class="row">

			<div class="col-4">
				<div class="mb-2">
					<label class="col-form-label text-secondary">Last Updated</label>
					<div>{{((matrix | async)?.updatedAt) | momentDate}}</div>
				</div>
			</div>

		</div>



	</div> <!-- left col with form -->

	<div class="col-2"> <!-- right col with action buttons -->
		<ng-container *ngIf="(matrix | async)?.state === 'BUSY'">
			<div class="spinner-border text-primary" role="status" style="width: 3rem; height: 3rem;">
				<span class="sr-only">Loading...</span>
			</div>
		</ng-container>

		<ng-container *ngIf="(matrix | async)?.state === 'FINALIZED'">
			<div class=" text-primary" role="status" style="width: 3rem; height: 3rem;">
				<span class="sr-only">No actions allowed</span>
			</div>
		</ng-container>

		<ng-container *ngIf="(matrix | async)?.state === 'OUT_OF_SYNC'">
			<button class="btn btn-success btn-block" (click)="recalculate()">Recalculate</button>
		</ng-container>

		<ng-container *ngIf="(matrix | async)?.state === 'IDLE'">
			<button class="btn btn-success btn-block" (click)="recalculate()">Recalculate</button>
			<button class="btn btn-warning btn-block" (click)="dispute()" [disabled]="!(canDispute | async)">Dispute</button>
			<button class="btn btn-warning btn-block" (click)="close()" [disabled]="!(canClose | async)">Close</button>
			<button class="btn btn-warning btn-block" (click)="lock()" [disabled]="!(canLock | async)">Lock for Settlement</button>
		</ng-container>

		<ng-container *ngIf="(matrix | async)?.state === 'LOCKED'">
			<button class="btn btn-warning btn-block" (click)="settle()" [disabled]="!(canSettle | async)">Settle</button>
			<button class="btn btn-warning btn-block" (click)="unlock()" >Unlock</button>
		</ng-container>

		<ng-container *ngIf="(matrix | async)?.state === 'FINALIZED'">
			<button class="btn btn-success btn-block" (click)="export()">Export</button>
		</ng-container>


		<!--<ng-container *ngIf="(matrix | async)?.state === 'CLOSED'">
			<button class="btn btn-success btn-block" (click)="recalculate()">Recalculate</button>
			<button class="btn btn-warning btn-block" (click)="dispute()">Dispute</button>
			<button class="btn btn-warning btn-block" (click)="lock()">Lock</button>
			&lt;!&ndash;<button class="btn btn-warning btn-block" (click)="settle()">Settle</button>&ndash;&gt;
		</ng-container>

		<ng-container *ngIf="(matrix | async)?.state === 'DISPUTED'">
			&lt;!&ndash;<button class="btn btn-success btn-block" (click)="recalculate()">Recalculate</button>&ndash;&gt;
			<button class="btn btn-warning btn-block" (click)="close()">Undispute (Close)</button>
		</ng-container>

		<ng-container *ngIf="(matrix | async)?.state === 'AWAITING_SETTLEMENT'">
			<button class="btn btn-warning btn-block" (click)="unlock()">Unlock</button>
			<button class="btn btn-warning btn-block" (click)="settle()">Settle</button>
		</ng-container>-->

<!--		<ng-container *ngIf="(matrix | async)?.state !== 'IDLE'">
			<button class="btn btn-primary btn-block" [disabled]="editing || (matrix | async)?.isActive"
					(click)="approve()" [hidden]="(matrix | async)?.type ==='HUB'">Approve
			</button>
		</ng-container>-->

	</div> <!-- right col with action buttons -->
</div>

<ul ngbNav #nav="ngbNav" class="mt-4 nav-tabs" (navChange)="tabChange($event)">

	<li ngbNavItem="batches" > <!-- batches -->
		<a ngbNavLink>Batches</a>
		<ng-template ngbNavContent>
			<h4 class="mt-3">Baches included in this matrix</h4>
			<table class="table table-hover mt-3">
				<thead class="thead-light">
				<tr>
					<th scope="col">Batch ID</th>
					<th scope="col">Debit Balance</th>
					<th scope="col">Credit Balance</th>
<!--					<th scope="col">Accounts</th>-->
					<th scope="col">State</th>

				</tr>
				</thead>
				<tbody>
				<ng-container *ngIf="(matrix | async)?.batches?.length==0">
					<tr class="" >
						<td colspan="6">No batches found, please try again with different criteria</td>
					</tr>
				</ng-container>
				<tr *ngFor="let item of (matrix | async)?.batches">
					<td>{{item.id}}</td>
					<td style="text-align: right;">{{ formatCommaSeparator(item.batchDebitBalance) }}</td>
					<td style="text-align: right;">{{ formatCommaSeparator(item.batchCreditBalance) }}</td>
<!--					<td>{{item.batchAccounts?.length}}</td>-->
					<td>{{item.state}}</td>
				</tr>
				</tbody>
			</table>
		</ng-template>
	</li> <!-- Batches -->

	<li ngbNavItem="perParticipantTotals" > <!-- per participant totals -->
		<a ngbNavLink>Balances by Participants</a>
		<ng-template ngbNavContent>
			<h4 class="mt-3">Per participant balances</h4>
			<table class="table table-hover mt-3">
				<thead class="thead-light">
				<tr>
					<th scope="col">Participant Id</th>
					<th scope="col">State</th>
					<th scope="col">Currency</th>
					<th scope="col">Credit Balance</th>
					<th scope="col">Debit Balance</th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let item of (matrix | async)?.balancesByParticipant">
					<td><a routerLink="/participants/{{item.participantId}}">{{item.participantId}}</a></td>
					<td>{{item.state}}</td>
					<td>{{item.currencyCode}}</td>
					<td style="text-align: right;">{{ formatCommaSeparator(item.creditBalance) }}</td>
					<td style="text-align: right;">{{ formatCommaSeparator(item.debitBalance) }}</td>
				</tr>
				</tbody>
			</table>
		</ng-template>
	</li> <!-- Per participant totals -->

	<li ngbNavItem="perStateTotals" > <!-- per state totals -->
		<a ngbNavLink>Balances by State</a>
		<ng-template ngbNavContent>
			<h4 class="mt-3">Per State balances</h4>
			<table class="table table-hover mt-3">
				<thead class="thead-light">
				<tr>
					<th scope="col">State</th>
					<th scope="col">Currency</th>
					<th scope="col">Credit Balance</th>
					<th scope="col">Debit Balance</th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let item of (matrix | async)?.balancesByStateAndCurrency">
					<td>{{item.state}}</td>
					<td>{{item.currencyCode}}</td>
					<td style="text-align: right;">{{ formatCommaSeparator(item.creditBalance) }}</td>
					<td style="text-align: right;">{{ formatCommaSeparator(item.debitBalance) }}</td>
				</tr>
				</tbody>
			</table>
		</ng-template>
	</li> <!-- Per state totals -->

	<li ngbNavItem="perCurrencyTotals" > <!-- per Currency totals -->
		<a ngbNavLink>Balances by Currency</a>
		<ng-template ngbNavContent>
			<h4 class="mt-3">Per Currency balances</h4>
			<table class="table table-hover mt-3">
				<thead class="thead-light">
				<tr>
					<th scope="col">Currency</th>
					<th scope="col">Credit Balance</th>
					<th scope="col">Debit Balance</th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let item of (matrix | async)?.balancesByCurrency">
					<td>{{item.currencyCode}}</td>
					<td style="text-align: right;">{{ formatCommaSeparator(item.creditBalance) }}</td>
					<td style="text-align: right;">{{ formatCommaSeparator(item.debitBalance) }}</td>
				</tr>
				</tbody>
			</table>
		</ng-template>
	</li> <!-- Per Currency totals -->


	<li ngbNavItem="transfers" > <!-- transfers -->
		<a ngbNavLink>Transfers</a>
		<ng-template ngbNavContent>
			<h4 class="mt-3">Transfers</h4>
			<table class="table table-hover mt-3">
				<thead class="thead-light">
				<tr>
					<th scope="col">Transfer Id</th>
					<th scope="col">Payer Id</th>
					<th scope="col">Payee Id</th>
					<th scope="col">Amount</th>
					<th scope="col">Timestamp</th>
					<th scope="col">Batch Id</th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let item of (transfers | async)">
					<td><a routerLink="/transfers/{{item.transferId}}">{{item.transferId}}</a></td>
					<td><a routerLink="/participants/{{item.payerFspId}}">{{item.payerFspId}}</a></td>
					<td><a routerLink="/participants/{{item.payeeFspId}}">{{item.payeeFspId}}</a></td>
					<td style="text-align: right;">{{ formatCommaSeparator(item.amount) }} ({{item.currencyCode}})</td>
					<td>{{item.transferTimestamp | momentDate}}</td>
					<td>{{item.batchId}}</td>
				</tr>
				</tbody>
			</table>
		</ng-template>
	</li> <!-- transfers -->
</ul>


<div class="mt-6" [ngbNavOutlet]="nav"></div>

<!--<pre>{{matrix | async | json}}</pre>-->
