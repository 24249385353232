<h3>Platform Configuration - Bounded Context Specific Configurations</h3>

<h4>Registered Bounded Contexts</h4>
<div>
  <table class="table">
    <thead class="thead-light">
    <tr>
      <th scope="col">Bounded Context</th>
      <th scope="col">Iteration #</th>

    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let bc of (bcs | async)">
      <td>{{bc.boundedContextName}} <span class="badge badge-secondary">{{bc.schemaVersion}}</span></td>
      <td>{{bc.iterationNumber}}</td>
    </tr>
    </tbody>
  </table>
</div>

<hr/>

<h4>Parameters</h4>
<div>
  <table class="table">
    <thead class="thead-light">
    <tr>
      <th scope="col">Bounded Context</th>
      <th scope="col">Param Name</th>
      <th scope="col">Type</th>
      <th scope="col">Default Value</th>
      <th scope="col">Current Value</th>
      <th scope="col">Description</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of (params | async)">
      <td>{{item.boundedContextName}}  <span class="badge badge-secondary">{{item.schemaVersion}}</span></td>
      <td>{{item.name}}</td>
      <td>{{item.type}}</td>
      <td>{{item.defaultValue}}</td>
      <td>{{item.currentValue}}</td>
      <td>{{item.description}}</td>
    </tr>
    </tbody>
  </table>
</div>


<h4>Feature Flags</h4>
<div>
  <table class="table">
    <thead class="thead-light">
    <tr>
      <th scope="col">Bounded Context</th>
      <th scope="col">Flag Name</th>

      <th scope="col">Default Value</th>
      <th scope="col">Current Value</th>
      <th scope="col">Description</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let flag of (featureFlags | async)">
      <td>{{flag.boundedContextName}} <span class="badge badge-secondary">{{flag.schemaVersion}}</span></td>
      <td>{{flag.name}}</td>

      <td>{{flag.defaultValue}}</td>
      <td>{{flag.currentValue}}</td>
      <td>{{flag.description}}</td>
    </tr>
    </tbody>
  </table>
</div>




<h4>Secrets</h4>
<div>
  <table class="table">
    <thead class="thead-light">
    <tr>
      <th scope="col">Bounded Context</th>
      <th scope="col">Secret Name</th>

      <th scope="col">Current Value</th>
      <th scope="col">Description</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let secret of (secrets | async)">
      <td>{{secret.boundedContextName}}  <span class="badge badge-secondary">{{secret.schemaVersion}}</span></td>
      <td>{{secret.name}}</td>

      <td>****</td>
      <td>{{secret.description}}</td>
    </tr>
    </tbody>
  </table>
</div>
