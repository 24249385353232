<div>
  <h2 class="mb-3">Create New Model</h2>
</div>

<form [formGroup]="form">
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="inputName">Name</label>
      <input
        type="text"
        class="form-control"
        id="inputName"
        placeholder="Model Name"
        formControlName="name"
      />
      <div class="invalid-feedback">Please choose a valid name</div>
    </div>

    <div class="form-group col-md-6">
      <label for="inputBatchInterval">Batch Interval (secs)</label>
      <input
        type="number"
        class="form-control"
        id="inputBatchInterval"
        placeholder="300"
        formControlName="batchCreateInterval"
      />
    </div>

  </div>

  <div class="form-row d-flex mb-3">
    <div class="p-2">
      <button type="submit" class="btn btn-primary" (click)="create()">
        Create Model
      </button>
    </div>
    <div class="ml-auto p-2">
      <button class="btn btn-danger" (click)="cancel()">Cancel</button>
    </div>
  </div>
</form>
