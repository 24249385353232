<div class="jumbotron">
  <div class="d-flex justify-content-center">

    <img style="width: 600px;" src="/assets/pch_logo.png"/>
  </div>

  <div class="row align-items-center" style="margin-bottom: 25px"></div>

  <div class="d-flex justify-content-center">
    <h1 class="display-4">{{ 'WELCOME' | translate }}</h1>
  </div>
  <div class="d-flex justify-content-center">
    <p class="lead">{{ 'INTRO' | translate }}</p>

  </div>
	<div class="d-flex justify-content-center">
		<p>{{ 'VERSION' | translate:{VERSION: getVersion(), ENVNAME: getEnvName()} }}</p>
	</div>
  <hr class="my-4 row align-items-center">
  <div class="d-flex justify-content-center">
    <p>{{ 'MANAGE_PLATFORM' | translate }}</p>
  </div>

  <ng-container *ngIf="!(isLoggedIn | async)">
    <div class="d-flex justify-content-center">
      <p>{{ 'LOGIN_PROMPT' | translate }}</p>
    </div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary" routerLink="/login">{{ 'LOGIN_BUTTON' | translate }}</button>
    </div>
  </ng-container>

</div>


<div style="text-align: center; font-size: 1.1rem;" class="mb-2">
	<a style="color: black" href="https://lacamara.mx/en/" aria-label="Link to https://lacamara.mx" target="_self" rel="noopener">
		https://lacamara.mx/en/
	</a>
</div>
<div class="container" style="text-align: center; font-size: 1.1rem;">

	<a style="color: black" class="mr-2" href="https://github.com/The-Peoples-Clearinghouse/" aria-label="Link to https://github.com/The-Peoples-Clearinghouse/" target="_self" rel="noopener">
		<i class="bi bi-github"></i>
	</a>
</div>
