<div class="row mb-2">
	<div class="col">
		<h3>Built-in Oracle Associations</h3>
	</div>
	<div class="col text-right">
		<button class="btn btn-success btn-labeled" routerLink="/account-lookup-associations/new">
			<span class="btn-label"><i class="bi bi-plus-lg"></i></span>Associate Participant to Oracle</button>
	</div>
</div>



<!--filter-->
<div class="list-page-filterbox">
	<form >
		<table class="table">
			<tr class="form-row justify-content-end">
				<div class="form-group col-md-2">
					<label for="filterPartyType">Party Type</label>
					<select class="form-control" id="filterPartyType">
						<option value="{{ALL_STR_ID}}">(All)</option>
						<option value="{{item}}" *ngFor="let item of (keywordPartyType | async)">{{item}}</option>
					</select>
				</div>
				<div class="form-group col-md-2">
					<label for="filterCurrency">Currency</label>
					<select class="form-control" id="filterCurrency">
						<option value="{{ALL_STR_ID}}">(All)</option>
						<option value="{{item}}" *ngFor="let item of (keywordCurrency | async)">{{item}}</option>
					</select>
				</div>
				<div class="form-group col-md-2">
					<label for="filterFspId">Participant</label>
					<select class="form-control" id="filterFspId">
						<option value="{{ALL_STR_ID}}">(All)</option>
						<option value="{{item}}" *ngFor="let item of (keywordFspId | async)">{{item}}</option>
					</select>
				</div>

				<div class="form-group col-md-2">
					<label for="filterPartyId">Party ID</label>
					<input class="form-control" type="text" id="filterPartyId" />
				</div>
				<div class="form-group col-md-2">
					<label for="filterPartySubType">Party Sub Type</label>
					<input class="form-control" type="text" id="filterPartySubType" />
				</div>

				<div class="form-group col-md-2">
					<label for="filterBtn">&nbsp;</label>
					<button type="submit" class="form-control btn btn-primary" id="filterBtn" (click)="search()">Search &nbsp;&nbsp;<i class="bi bi-search"></i></button>
				</div>
			</tr>
		</table>
	</form>
</div>

<ng-container *ngIf="(registeredAssociations | async)?.length==0 else listOracles">
  <div class="alert alert-warning" role="alert">
    No Associations found, please refine your search.
  </div>
</ng-container>

<ng-template #listOracles>
    <div>
        <table class="table">
            <thead class="thead-light">
                <tr>
                    <th scope="col">FspId</th>
                    <th scope="col">PartyId</th>
                    <th scope="col">PartyType</th>
                    <th scope="col">PartySubType</th>
                    <th scope="col">Currency</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of (registeredAssociations | async)">
                    <td>{{item.fspId}}</td>
                    <td>{{item.partyId}}</td>
                    <td>{{item.partyType}}</td>
                    <td>{{item.partySubType}}</td>
                    <td>{{item.currency}}</td>
					<td (click)="false;$event.stopPropagation()">
						<div class="btn-group btn-group-sm" role="group" aria-label="">
						  <button type="button" class="btn btn-danger" placement="top" container="body" ngbTooltip="Remove Oracle" (click)="removeOracleParticipantAssociation(item.fspId,item.partyId,item.partyType,item.partySubType,item.currency)">
						  	<span class="bi bi-trash"></span>
						  </button>
						</div>
					  </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>


<!--pagination
TODO: put in component
-->
<hr>
<nav aria-label="Page navigation"  *ngIf="paginateResult | async; let paginateObj">
	<ul class="pagination justify-content-end">
		<li class="page-item" [ngClass]="{'disabled' : paginateObj.prev == null}">
			<a class="page-link" aria-label="Previous" (click)="search(paginateObj.prev ? paginateObj.prev-1 : 0)">
				<span aria-hidden="true">&laquo;</span>
				<span class="sr-only">Previous</span>
			</a>
		</li>

		<ng-container *ngFor="let item of paginateObj.items">
			<li class="page-item" *ngIf="item != null" [ngClass]="{'active' : item === paginateObj.current}">
				<a class="page-link" (click)="search(item-1)" >{{item}}
					<span class="sr-only" *ngIf="item === paginateObj.current">(current)</span>
				</a>
			</li>
			<li class="page-item disabled" *ngIf="item == null">
				<a class="page-link disabled" *ngIf="!item" >...</a>
			</li>
		</ng-container>

		<li class="page-item"  [ngClass]="{'disabled' : paginateObj.next == null}">
			<a class="page-link" aria-label="Next" (click)="search(paginateObj.next ? paginateObj.next-1 : 0)">
				<span aria-hidden="true">&raquo;</span>
				<span class="sr-only">Next</span>
			</a>
		</li>
	</ul>
</nav>
